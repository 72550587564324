import sizeof from 'object-sizeof'
import { createStore, createHook, defaults } from 'react-sweet-state'

import trackEvent from 'lib/trackEvent'
import { getCurrencySymbol } from 'lib/utils/currency'
import { CHANNEL_TYPES } from 'lib/utils/channel'

import actions from './actions'
import initialState from './initialState'
import { headerMapper } from './mapper'

defaults.devtools = true

const Store = createStore({
  name: 'ZOKO-auth-' + window.location.origin,
  initialState,
  actions,
})

const logger = (storeState) => (next) => (arg) => {
  const result = next(arg)
  trackEvent(
    trackEvent.modules.redux,
    trackEvent.events.redux_action,
    {
      name: storeState?.key,
      action_name: storeState?.mutator?.actionName,
      payload_size: sizeof(arg),
    },
    true
  )
  return result
}

// disabled logs for mixpanel
!window && defaults.middlewares.add(logger)

export const useHostInfo = createHook(Store, {
  selector: (state) => state.hostInfo,
})

//for twerlo and other whitelabelled web apps showBilling will be false
export const useIsWhiteLabelled = createHook(Store, {
  selector: (state) => !state.hostInfo?.showBilling,
})

export const useAuth = createHook(Store, {
  selector: (state) => state.userData,
})

export const useAuthHeaders = createHook(Store, {
  selector: (state) => {
    return headerMapper({
      storeId: state.currentStoreId,
    })
  },
})

export const useStoreList = createHook(Store, {
  selector: (state) => ({
    storeList: window?.globals?.storeList,
    currentStoreId: state?.currentStoreId,
    storeListUpdatedAt: state.storeListUpdatedAt,
  }),
})

export const useCurrentStoreId = createHook(Store, {
  selector: (state) => {
    return state?.currentStoreId
  },
})

export const useCurrentStoreTypeForProducts = createHook(Store, {
  selector: (state) => {
    return state?.currentStoreTypeForProductsTab
  },
})

export const useCurrentStoreType = createHook(Store, {
  selector: (state) => {
    return state?.currentStoreType
  },
})

export const useCurrentStoreName = createHook(Store, {
  selector: (state) => {
    const { currentStoreId } = state
    const currentStore = window?.globals?.storeList?.find(
      ({ id }) => id === currentStoreId
    )
    return currentStore?.name || ''
  },
})

export const useCurrentStoreCurrency = createHook(Store, {
  selector: (state) => {
    const { currentStoreId } = state
    const currentStore = window?.globals?.storeList?.find(
      ({ id }) => id === currentStoreId
    )
    return getCurrencySymbol(currentStore?.billing_currency)
  },
})

export const useCurrentStore = createHook(Store, {
  selector: (state) => ({
    currentStore: window?.globals?.storeList?.find(
      ({ id }) => id === state?.currentStoreId
    ),
    userData: state?.userData,
    storeListUpdatedAt: state.storeListUpdatedAt,
  }),
})

export const useMetaPayment = createHook(Store, {
  selector: (state) => {
    const currentStore = window?.globals?.storeList?.find(
      ({ id }) => id === state?.currentStoreId
    )
    return {
      isMetaPaymentPending: !currentStore?.meta_payment_status,
    }
  },
})

export const useCriticalAlertData = createHook(Store, {
  selector: (state) => ({
    currentStoreId: state?.currentStoreId,
    storeList: window?.globals?.storeList,
  }),
})

export const useShowViewAs = createHook(Store, {
  selector: (state) => state.showViewAs,
})

export const useRole = createHook(Store, {
  selector: (state) =>
    window?.globals?.storeList?.find(({ id }) => id === state?.currentStoreId)
      ?.role,
})

export const useMqttConnectionStatus = createHook(Store, {
  selector: (state) => state.mqttConnected,
})

export const useMobile = createHook(Store, {
  selector: (state) => state.isMobile,
})

export const useInternetStatus = createHook(Store, {
  selector: (state) => state.internetConnected,
})

export const useUserDataPreset = createHook(Store, {
  selector: (state) => state.preset,
})

export const useAvatarImageUploading = createHook(Store, {
  selector: (state) => ({
    avatarImageUploading: state.avatarImageUploading,
    profileLoader: state.profileLoader,
  }),
})

export const useStoreImageUploading = createHook(Store, {
  selector: (state) => ({
    storeImageUploading: state.storeImageUploading,
    accountSettingsLoader: state.accountSettingsLoader,
  }),
})

export const useAccountDataPreset = createHook(Store, {
  selector: (state) => ({
    storeName: state.storePreset?.name,
    storeImage: state.storePreset?.image,
  }),
})

export const useForgotPasswordData = createHook(Store, {
  selector: (state) => state.passwordResetRequested,
})

export const useAuthLoader = createHook(Store, {
  selector: (state) => state.authLoader,
})

export const useCreateAccountData = createHook(Store, {
  selector: (state) => ({
    storeName: state.storePreset?.name,
    billingCurrency: state.storePreset?.billingCurrency,
    orgLoader: state.orgLoader,
    storeList: window?.globals?.storeList,
  }),
})

export const useConnectWhatsapp = createHook(Store, {
  selector: (state) => ({
    currentStoreId: state?.currentStoreId,
    connectWhatsappLoader: state.connectWhatsappLoader,
    connectWhatsappInitiated: state.connectWhatsappInitiated,
  }),
})

export const useDialoge360Banners = createHook(Store, {
  selector: (state) => ({
    accountStatus: state.accountStatus,
  }),
})

export const useWhatsAppAccountStatus = createHook(Store, {
  selector: (state) =>
    state?.accountStatus?.connections?.find?.(
      (connection) => connection?.channel === CHANNEL_TYPES.whatsapp.value
    ),
})

export const useUserInfoFormData = createHook(Store, {
  selector: (state) => state.userForm,
})

export const useCurrentStoreIntegrations = createHook(Store, {
  selector: (state) => ({
    currentStoreIntegrations: window?.globals?.storeList?.find(
      (store) => store.id === state?.currentStoreId
    )?.integrations,
  }),
})

export const useCatalogExpand = createHook(Store, {
  selector: (state) => state.catalogExpand,
})

export const useCatalogExpandShow = createHook(Store, {
  selector: (state) => state.showCatalogExpand,
})

export const useCurrentUserEmail = createHook(Store, {
  selector: (state) => ({
    email: state.userData?.email,
  }),
})

export const useCurrentStoreBillingType = createHook(Store, {
  selector: (state) => ({
    currentBillingType: window?.globals?.storeList?.find(
      (store) => store.id === state?.currentStoreId
    )?.billing_currency,
  }),
})

export const useAgentStatusLoader = createHook(Store, {
  selector: (state) => state.agentStatusLoader,
})

export const useMatchedStore = createHook(Store, {
  selector: (state) => state.matchedStore,
})

export const useMetaCloudStatus = createHook(Store, {
  selector: (state) => state.metaConnectStatus,
})

export const useLitePlanOnboardingData = createHook(Store, {
  selector: (state) => ({
    showLitePlanOnboardingFlow: state.showLitePlanOnboardingFlow,
  }),
})
