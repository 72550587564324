export const isEmpty = (obj) => {
  return Object.keys(obj)?.length === 0
}

export const deepClone = (obj) => {
  try {
    return JSON.parse(JSON.stringify(obj))
  } catch (err) {
    return obj
  }
}
export const asValueArray = (obj) => Object.values(obj)

export const dynamicSort = ({ data = [], sortField, sortDir = 'asc' }) =>
  data?.sort((a, b) => {
    const comparison =
      a[sortField] > b[sortField] ? 1 : b[sortField] > a[sortField] ? -1 : 0
    return sortDir === 'desc' ? comparison * -1 : comparison
  })

export const findPropertyByValue = (obj, value) => {
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] === value) {
      return key // Return the property name
    }
  }
  return null // Return null if no match is found
}
