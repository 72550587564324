import { icons } from 'common/UI/Icon'
import { integrationRoutes, settingsRoutes } from 'lib/config/routes'
import { useSelectedTab } from 'modules/Settings/store'

export const INTEGRATIONTYPES = {
  INSTAGRAM: 'instagram',
  FACEBOOK: 'facebook',
  WHATSAPP: 'whatsapp',
  SHOPIFY: 'shopify',
  ADSMANAGER: 'adsManager',
  ECOMMERCE: 'ecommerce',
  RAZORPAY: 'razorPay',
  WHATSAPP_PAY: 'whatsAppPay',
  CHATGPT: 'chatGPT',
}

export const ECOMMERCETYPES = {
  WOOCOMMERCE: 'woocommerce',
  SHOPIFY: 'shopify',
}

export const STORETYPES = {
  ecomm: 'ecomm',
  legacy: 'legacy',
}

export const chatGPTIntegrationErrorCodes = [
  'invalid_organization',
  'invalid_api_key',
]

export const chatGPTIntegrationErrorMapper = {
  [chatGPTIntegrationErrorCodes[0]]: 'Invalid organization id',
  [chatGPTIntegrationErrorCodes[1]]: 'Invalid  API key',
}

export const shopifyIntegrationErrorCodes = [
  'invalid_store_url',
  'invalid_access_token',
  'invalid_storefront_access_token',
  'invalid_api_key',
  'invalid_secret_key',
  'belongs_to_different_store',
  'pii_access_denied',
  'access_scope_missing',
  'store_sync_in_progress',
]

export const shopifyIntegrationErrorMapper = {
  [shopifyIntegrationErrorCodes[0]]: 'Invalid public store url',
  [shopifyIntegrationErrorCodes[1]]: 'Invalid Admin API Access token',
  [shopifyIntegrationErrorCodes[2]]: 'Invalid Storefront API access token',
  [shopifyIntegrationErrorCodes[3]]: 'Invalid API key',
  [shopifyIntegrationErrorCodes[4]]: 'Invalid API secret key',
  [shopifyIntegrationErrorCodes[5]]: 'This API key belongs to another store',
  [shopifyIntegrationErrorCodes[6]]:
    'Not available on Shopify’s Basic Plan. Upgrade your Shopify Plan and try again.',
  [shopifyIntegrationErrorCodes[7]]:
    'Critical permissions missing! Give all necessary permissions before connecting ',
  [shopifyIntegrationErrorCodes[8]]:
    'Store sync in progress. Please try after some time.',
}

export const shopifyBannerIntegrationErrors = {
  webhook_missing: 'Webhook missing.',
  inactive_shop: 'Inactive shopify store',
  invalid_access_token: 'Invalid access token',
  access_scope_missing: 'Critical permissions missing.',
}

export const razorPayIntegrationErrorCodes = [
  'invalid_key_id',
  'invalid_secret_key',
]

export const razorPayIntegrationErrorMapper = {
  invalid_key_id: 'Invalid Key ID',
  invalid_secret_key: 'Invalid secret key',
}

export const integrationIconMapper = {
  shopify: icons.shopifyLogo,
  woocommerce: icons.woocommerceLogo,
}

export const integrationCategories = {
  ecommerce: 'ecommerce',
  payment: 'payment',
  messaging: 'messaging',
  others: 'others',
}

export const integrationSubRouteMapper = {
  'razor-pay': integrationCategories.payment,
  facebook: integrationCategories.messaging,
  instagram: integrationCategories.messaging,
  adsmanager: integrationCategories.others,
  'shopify-custom-app': integrationCategories.ecommerce,
  woocommerce: integrationCategories.ecommerce,
  'chet-gpt': integrationCategories.others,
}

export const integrationCategoriesMapper = {
  [integrationCategories.ecommerce]: integrationRoutes.ECOMMERCE,
  [integrationCategories.payment]: integrationRoutes.PAYMENT,
  [integrationCategories.messaging]: integrationRoutes.MESSAGING,
  [integrationCategories.others]: integrationRoutes.OTHERS,
}

export const useIntegrationFilter = (integrations, filterKey = 'type') => {
  const baseRoute = settingsRoutes.INTEGRATIONS
  const [selectedTab] = useSelectedTab()
  return integrations.filter((integration) => {
    const _category = integration[filterKey] || ''
    return (
      selectedTab === baseRoute ||
      selectedTab.replace(baseRoute + '/', '') === _category
    )
  })
}
