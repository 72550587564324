const routes = {
  INITIAL: '/',
  CHAT: '/chat',
  QUEUE: '/queue',
  LOGIN: '/login',
  VERIFY_PHONE: '/verify-phone',
  SETTINGS: '/settings',
  APPS: '/apps',
  FORGOT_PASSWORD: '/forgot-password',
  FLOWS: '/flows',
  SIGNUP: '/signup',
  JOIN: '/join',
  SIGNOUT: '/signout',
  OVERVIEW: '/overview',
  WIDGET: '/widget',
  APPCLIP: '/appclip',
  CREATE_ACCOUNT: '/create-account',
  CONNECT_WHATSAPP: '/connect-whatsapp',
  WHATSAPP_CONNECT_SUCCESS: '/connect-success',
  PHONE_PE_PENDING: '/phone_pe_pending',
  SHOPIFY_STORE_MATCHER: '/store-matcher',
  SHOPIFY_ERROR_SCREEN: '/shopify-error',
  STORE_SWITCHER: '/store-switch',
  ACCESS_FAILED: '/access-failed',
  ROI_HUB: '/hub',
  ADMIN: '/admin',
  CONNECT_META_CLOUD: '/meta-connect',
}

export const settingsRoutes = {
  BILLING: `${routes.SETTINGS}/billing`,
  USAGE_REPORTS: `${routes.SETTINGS}/usage-reports`,
  BILLING_NOTIFICATIONS: `${routes.SETTINGS}/billing-notifications`,
  CONTACTS: `${routes.SETTINGS}/contacts`,
  PROFILE: `${routes.SETTINGS}/profile`,
  WEB_HOOKS: `${routes.SETTINGS}/webhooks`,
  APIKEYS: `${routes.SETTINGS}/api-keys`,
  TEAM_MANAGEMENT: `${routes.SETTINGS}/teams`,
  AGENT_MANAGEMENT: `${routes.SETTINGS}/agents`,
  BUSINESS_MESSAGES: `${routes.SETTINGS}/greeting-and-away-messages`,
  INTEGRATIONS: `${routes.SETTINGS}/integrations`,
  MESSAGE_LOGS: `${routes.SETTINGS}/message-logs`,
  DELETE_ACCOUNT: `${routes.SETTINGS}/delete-account`,
  AGENT_RATES: `${routes.SETTINGS}/agent-rates`,
  WHATSAPP_PROFILE: `${routes.SETTINGS}/whatsapp-profile`,
}

export const integrationRoutes = {
  ALL: `${settingsRoutes.INTEGRATIONS}`,
  ECOMMERCE: `${settingsRoutes.INTEGRATIONS}/ecommerce`,
  PAYMENT: `${settingsRoutes.INTEGRATIONS}/payment`,
  MESSAGING: `${settingsRoutes.INTEGRATIONS}/messaging`,
  OTHERS: `${settingsRoutes.INTEGRATIONS}/others`,
}

export const overviewRoutes = {
  DASHBOARD: routes.OVERVIEW,
  MARKETING_OVERVIEW: `${routes.OVERVIEW}/marketing`,
}

export const appsRoutes = {
  QUICK_REPLIES: `${routes.APPS}/quick-replies`,
  BROADCAST: `${routes.APPS}/broadcast`,
  TEMPLATES: `${routes.APPS}/templates`,
  ANALYTICS: `${routes.APPS}/analytics`,
  POPUP_CAMPAIGN: `${routes.APPS}/popup-campaigns`,
  LINK_CREATOR: `${routes.APPS}/link-creator`,
  RULES_ENGINE: `${routes.APPS}/rules-engine`,
  SEGMENTS: `${routes.APPS}/segments`,
  CLICK_TO_CHAT: `${routes.APPS}/click-to-chat`,
  CLICK_TO_WHATSAPP: `${routes.APPS}/click-to-whatsapp-ads`,
  ENIGMA_SALES: `${routes.APPS}/enigma-sales`,
  HALLO: `${routes.APPS}/hallo`,
  WISMO: `${routes.APPS}/wismo`,
  SELLO: `${routes.APPS}/sello`,
  GURU: `${routes.APPS}/guru`,
  APPS_LIST: routes.APPS,
  CATALOG_MANAGER: `${routes.APPS}/catalog`,
}

export const BROADCAST_ROUTES = {
  DETAILS: `${appsRoutes.BROADCAST}/:broadcastId`,
}

export const CLICK_TO_WHATSAPP_ROUTES = {
  DETAILS_OVERVIEW: `${appsRoutes.CLICK_TO_WHATSAPP}/:adId`,
  DETAILS_DATA: `${appsRoutes.CLICK_TO_WHATSAPP}/:adId/data`,
}

export const FLOW_ROUTES = {
  NEW_FLOW: `${routes.FLOWS}/new`,
  BUILDER_NEW: `${routes.FLOWS}/builder`,
  BUILDER_OVERVIEW: `${routes.FLOWS}/builder/:id`,
  BUILDER_EDIT: `${routes.FLOWS}/builder/:id/edit`,
  BUILDER_ANALYTICS: `${routes.FLOWS}/builder/:id/analytics`,
}

export const analyticsRoutes = {
  OVERVIEW: `${appsRoutes.ANALYTICS}`,
  CHAT_REPORTS: `${appsRoutes.ANALYTICS}/chat-reports`,
  AGENT_REPORTS: `${appsRoutes.ANALYTICS}/agent-reports`,
  AGENT_REPORT_DETAIL: `${appsRoutes.ANALYTICS}/agent-reports/:id`,
}

export const integrationsApps = {
  SHOPIFY_APP: `${settingsRoutes.INTEGRATIONS}/shopify`,
  FACEBOOK_APP: `${settingsRoutes.INTEGRATIONS}/facebook`,
  INSTAGRAM_APP: `${settingsRoutes.INTEGRATIONS}/instagram`,
  ADSMANAGER: `${settingsRoutes.INTEGRATIONS}/adsmanager`,
  ECOMMERCE: `${settingsRoutes.INTEGRATIONS}/ecommerce`,
  SHOPIFY_CUSTOM_APP: `${settingsRoutes.INTEGRATIONS}/shopify-custom-app`,
  RAZOR_PAY: `${settingsRoutes.INTEGRATIONS}/razor-pay`,
  CHAT_GPT: `${settingsRoutes.INTEGRATIONS}/chat-gpt`,
  WHATSAPP_PAY: `${settingsRoutes.INTEGRATIONS}/whatsapp-pay`,
}

export const facebookAppRoutes = {
  FACEBOOK_NEW_CONNECTION: `${integrationsApps.FACEBOOK_APP}`,
}
export const ecommerceAppRoutes = {
  ECOMMERCE_NEW_CONNECTION: `${integrationsApps.ECOMMERCE}/:platform`,
  ECOMMERCE_EDIT_CONNECTION: `${integrationsApps.ECOMMERCE}/:platform/:id`,
}

export const instagramAppRoutes = {
  INSTAGRAM_NEW_CONNECTION: `${integrationsApps.INSTAGRAM_APP}`,
}

export const adsManagerAppRoutes = {
  ADSMANAGER_NEW_CONNECTION: `${integrationsApps.ADSMANAGER}`,
}

export const shopifyAppGrantRoutes = {
  SHOPIFY_GRANT_PRICING: `${integrationsApps.SHOPIFY_APP}/grant/pricing`,
  SHOPIFY_GRANT_CONTINUE: `${integrationsApps.SHOPIFY_APP}/grant/continue`,
  SHOPIFY_GRANT_PAYMENTS: `${integrationsApps.SHOPIFY_APP}/grant/payments`,
  SHOPIFY_GRANT_RESET: `${integrationsApps.SHOPIFY_APP}/grant/reset`,
  SHOPIFY_GRANT_OVERDUE: `${integrationsApps.SHOPIFY_APP}/grant/overdue`,
  SHOPIFY_GRANT_SCOPE: `${integrationsApps.SHOPIFY_APP}/grant/scope`,
  SHOPIFY_GRANT_MATCHER: `${integrationsApps.SHOPIFY_APP}/grant/matcher`,
  SHOPIFY_GRANT_INSTALL_COMPLETE: `${integrationsApps.SHOPIFY_APP}/grant/installcomplete`,
}

export const billingRoutes = {
  PLAN_CREDITS: `${settingsRoutes.BILLING}/plans-credits`,
  EDIT_PLAN: `${settingsRoutes.BILLING}/plans`,
  ADD_CREDITS: `${settingsRoutes.BILLING}/credits`,
  EDIT_CARD: `${settingsRoutes.BILLING}/cards`,
  EDIT_ADDRESS: `${settingsRoutes.BILLING}/address`,
  ORDER_SUMMARY: `${settingsRoutes.BILLING}/summary`,
  NOTIFICATION: `${settingsRoutes.BILLING}/notification`,
  PAYMENT_PENDING: `${settingsRoutes.BILLING}/pending`,
  PAYMENT_FRAME: `${settingsRoutes.BILLING}/external`,
  WALLET: `${settingsRoutes.BILLING}`,
  STRIPE_SUCCESS: `${settingsRoutes.BILLING}/stripe-success`,
}

export const rulesEngineRoutes = {
  ROUTING_RULES: `${appsRoutes.RULES_ENGINE}`,
  AUTO_RULES: `${appsRoutes.RULES_ENGINE}/auto-routing`,
  AUTO_CLOSE_CHATS: `${appsRoutes.RULES_ENGINE}/auto-close`,
}

export const segmentRoutes = {
  EDITCLONE: `${appsRoutes.SEGMENTS}/:id/:action`,
  NEW: `${appsRoutes.SEGMENTS}/new`,
}

export const CATALOGUE_ROUTES = {
  CATALOG_LISTING: '/',
  CART: '/cart',
  ADDRESS: '/address',
  CHECKOUT: '/checkout',
  VIEW_MULTI_PRODUCT: '/collection',
}

export const appListRoutes = {
  ALL: appsRoutes.APPS_LIST + '',
  PRODUCTIVITY: appsRoutes.APPS_LIST + '/productivity',
  UTILITIES: appsRoutes.APPS_LIST + '/utilities',
  MARKETING: appsRoutes.APPS_LIST + '/marketing',
  SUPPORT: appsRoutes.APPS_LIST + '/support',
  CHATGPT_BOTS: appsRoutes.APPS_LIST + '/chatgpt_bots',
  PARTNERS: appsRoutes.APPS_LIST + '/partners',
  SALES: appsRoutes.APPS_LIST + '/sales',
}

export const roiRoutes = {
  REVENUE_INSIGHTS: routes.ROI_HUB,
  REVENUE_LOGS: routes.ROI_HUB + '/logs',
}

export const ADMIN_ROUTES = {
  HEALTH_LOG: routes.ADMIN + '/health-log',
  VERSION_MANAGER: routes.ADMIN + '/version-manager',
  ADMIN_BANNER: routes.ADMIN + '/admin-banner',
  ADMIN_BLOCKER: routes.ADMIN + '/admin-blocker',
}

export const IGNORE_BANNER_LIST = [
  routes.CONNECT_META_CLOUD,
  routes.CONNECT_WHATSAPP,
  routes.WHATSAPP_CONNECT_SUCCESS,
  routes.SIGNUP,
  routes.LOGIN,
  routes.CREATE_ACCOUNT,
]

// set default route here
routes.CHAT = routes.INITIAL

export default routes
