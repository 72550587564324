import debounce from 'debounce'
import { doc, getDoc, onSnapshot } from 'firebase/firestore'

import routes from 'lib/config/routes'
import { firebaseAuth, getFirestore, logout } from 'lib/firebase'
import i18n from 'lib/i18n'
import { ZOKO_COLLECTIONS } from 'modules/Admin/store/constants'

export const setResize =
  ({ authActions, isMobile }) =>
  () => {
    debounce(() => {
      const _mobileCutOff = window
        .getComputedStyle(document.body)
        .getPropertyValue('--mobile-width')
      const mobileCutOff = Number(_mobileCutOff.replace('px', ''))
      const windowWidth = Number(window.innerWidth)
      const _isMobile = windowWidth <= mobileCutOff
      if (isMobile !== _isMobile) {
        authActions.setMobile(_isMobile)
      }
    }, 600)()
  }

// activateNotifications() tries to enable notifications
// and returns true if it succeeds and false otherwise...
export const activateNotifications = () =>
  new Promise((resolve) => {
    const notificationsSupported = Boolean(
      window.BroadcastChannel && window.Notification
    )
    if (notificationsSupported) {
      const notificationPermission = window.Notification?.permission // current permission state
      const isNotificationsPermissionAsked = ['granted', 'denied'].includes(
        notificationPermission
      )
      if (!isNotificationsPermissionAsked) {
        window.globals.service.toast(i18n.t('notificationsPermission'), {
          id: 'fcm',
          button: {
            label: i18n.t('enableDisable'),
            onClick: async () => {
              // If notifications are granted for the first,
              //do a force reload for the settings to get enabled on some browsers

              await Notification.requestPermission()
              window.location.reload()
            },
          },
        })
      } else if (notificationPermission === 'granted') {
        resolve(true)
      }
      resolve(false)
    } else {
      window.globals.service.toast('notificationsError')
      resolve(false)
    }
  })

export const billingBannerRoutesBlacklist = [
  routes.CONNECT_WHATSAPP,
  routes.SHOPIFY_STORE_MATCHER,
  routes.SHOPIFY_ERROR_SCREEN,
  routes.STORE_SWITCHER,
]

export const kickOutUser = () => {
  window.globals.service.toast(
    'Your account has been blocked for security reasons. Please contact support to unblock',
    {
      type: 'error',
      duration: 10,
      id: 'user-block',
    }
  )
  logout()
}

const logoutOnUserBlocking = (docSnap) => {
  if (docSnap?.exists?.()) {
    const data = docSnap?.data?.()
    if (data?.uid) {
      kickOutUser()
    }
  }
}

export const checkForUserBlocks = async (email) => {
  const user = await firebaseAuth?.currentUser?.getIdTokenResult?.()
  if (user?.claims?.blocked) {
    kickOutUser()
  }
  if (!email) {
    return window.console.error('checkForUserBlocks', 'no email')
  }
  const db = getFirestore()
  const docRef = doc(db, ZOKO_COLLECTIONS.blocked_users, email)
  const docSnap = await getDoc(docRef)
  logoutOnUserBlocking(docSnap)
  const unsub = onSnapshot(docRef, logoutOnUserBlocking)
  return unsub
}
