import arrowElbowDownLeft from 'common/assets/icons/files/arrow-elbow-down-left.svg'
import alt from 'common/assets/icons/files/alt.svg'
import calendar from 'common/assets/icons/files/calendar.svg'
import caretDown from 'common/assets/icons/files/caret-down.svg'
import caretDownLight from 'common/assets/icons/files/caret-down-light.svg'
import circleLoading from 'common/assets/icons/files/circle-loading.svg'
import chatBubble from 'common/assets/icons/files/chat-bubble.svg'
import chatBubbleOutline from 'common/assets/icons/files/chat-bubble-outline.svg'
import chatBubbleOutlineSelected from 'common/assets/icons/files/chat-bubble-outline-selected.svg'
import chatBalloon from 'common/assets/icons/files/chat-balloon.svg'
import clip from 'common/assets/icons/files/clip.svg'
import clipLight from 'common/assets/icons/files/clip-light.svg'
import close from 'common/assets/icons/files/close.svg'
import closeChat from 'common/assets/icons/files/close-chat.svg'
import document from 'common/assets/icons/files/document.svg'
import dot from 'common/assets/icons/files/dot.svg'
import dots from 'common/assets/icons/files/dots.svg'
import dotsCircle from 'common/assets/icons/files/dots-circle.svg'
import dragDots from 'common/assets/icons/files/drag-dots.svg'
import emoji from 'common/assets/icons/files/emoji.svg'
import unknownFile from 'common/assets/icons/files/unknown-file.svg'
import filter from 'common/assets/icons/files/filter.svg'
import iconPlaceholder from 'common/assets/icons/files/icon-placeholder.svg'
import image from 'common/assets/icons/files/image.svg'
import info from 'common/assets/icons/files/info.svg'
import instagram from 'common/assets/icons/files/instagram.svg'
import leftArrow from 'common/assets/icons/files/left-arrow.svg'
import rightArrow from 'common/assets/icons/files/right-arrow.svg'
import lessCircle from 'common/assets/icons/files/less-circle.svg'
import lessCircleLight from 'common/assets/icons/files/lessCircleLight.svg'
import link from 'common/assets/icons/files/link.svg'
import location from 'common/assets/icons/files/location.svg'
import logo from 'common/assets/icons/files/logo.svg'
import magnifierOutline from 'common/assets/icons/files/magnifier-outline.svg'
import microphone from 'common/assets/icons/files/microphone.svg'
import microphoneLight from 'common/assets/icons/files/microphone-light.svg'
import morePhotos from 'common/assets/icons/files/more-photos.svg'
import notes from 'common/assets/icons/files/notes.svg'
import notesLight from 'common/assets/icons/files/notes-light.svg'
import online from 'common/assets/icons/files/online.svg'
import offline from 'common/assets/icons/files/offline.svg'
import orders from 'common/assets/icons/files/orders.svg'
import panelIndicator from 'common/assets/icons/files/panel-indicator.svg'
import pencil from 'common/assets/icons/files/pencil.svg'
import pinOutline from 'common/assets/icons/files/pin-outline.svg'
import play from 'common/assets/icons/files/play.svg'
import playLight from 'common/assets/icons/files/play-light.svg'
import playDark from 'common/assets/icons/files/play-dark.svg'
import plus from 'common/assets/icons/files/plus.svg'
import plusOutline from 'common/assets/icons/files/plus-outline.svg'
import plusOutlineWhite from 'common/assets/icons/files/plusOutlineWhite.svg'
import plusCircle from 'common/assets/icons/files/plus-circle.svg'
import phone from 'common/assets/icons/files/phone.svg'
import queue from 'common/assets/icons/files/queue.svg'
import queueOutline from 'common/assets/icons/files/queue-outline.svg'
import queueOutlineSelected from 'common/assets/icons/files/queue-outline-selected.svg'
import quickReply from 'common/assets/icons/files/quick-reply.svg'
import recentClock from 'common/assets/icons/files/recent-clock.svg'
import sendToChat from 'common/assets/icons/files/send-to-chat.svg'
import search from 'common/assets/icons/files/search.svg'
import send from 'common/assets/icons/files/send.svg'
import sendWhite from 'common/assets/icons/files/send-white.svg'
import sendAudio from 'common/assets/icons/files/send-audio.svg'
import settings from 'common/assets/icons/files/settings.svg'
import shortcut from 'common/assets/icons/files/shortcut.svg'
import smileOutline from 'common/assets/icons/files/smile-outline.svg'
import smileLightOutline from 'common/assets/icons/files/smile-outline-light.svg'
import squares from 'common/assets/icons/files/squares.svg'
import storeBag from 'common/assets/icons/files/store-bag.svg'
import stopLight from 'common/assets/icons/files/stop-light.svg'
import tag from 'common/assets/icons/files/tag.svg'
import tagBold from 'common/assets/icons/files/tag_bold.svg'
import threeDots from 'common/assets/icons/files/three-dots.svg'
import type from 'common/assets/icons/files/type.svg'
import typeLight from 'common/assets/icons/files/type-light.svg'
import users from 'common/assets/icons/files/users.svg'
import stop from 'common/assets/icons/files/stop.svg'
import xCircle from 'common/assets/icons/files/x-circle.svg'
import xCircleLight from 'common/assets/icons/files/x-circle-light.svg'
import zap from 'common/assets/icons/files/zap.svg'
import checkCircle from 'common/assets/icons/files/check-circle.svg'
import whiteClose from 'common/assets/icons/files/white-close.svg'
import errorCircle from 'common/assets/icons/files/error-circle.svg'
import zapWhite from 'common/assets/icons/files/zap-white.svg'
import zapLight from 'common/assets/icons/files/zap-light.svg'
import read from 'common/assets/icons/files/read.svg'
import delivered from 'common/assets/icons/files/delivered.svg'
import accepted from 'common/assets/icons/files/accepted.svg'
import picture from 'common/assets/icons/files/picture.svg'
import dotMenu from 'common/assets/icons/files/dot-menu.svg'
import closeGrey from 'common/assets/icons/files/close-grey.svg'
import tick from 'common/assets/icons/files/tick.svg'
import templates from 'common/assets/icons/files/templates.svg'
import download from 'common/assets/icons/files/download.svg'
import downloadOutline from 'common/assets/icons/files/download-outline.svg'
import productListEmpty from 'common/assets/icons/files/product-list-empty.svg'
import xPurple from 'common/assets/icons/files/xPurple.svg'
import xBlue from 'common/assets/icons/files/xBlue.svg'
import xBrown from 'common/assets/icons/files/xBrown.svg'
import addAddress from 'common/assets/icons/files/addAddress.svg'
import newTag from 'common/assets/icons/files/newTag.svg'
import tickAccent from 'common/assets/icons/files/tick-accent.svg'
import tickBlack from 'common/assets/icons/files/tick-black.svg'
import dockClose from 'common/assets/icons/files/dock-close.svg'
import dockOpen from 'common/assets/icons/files/dock-open.svg'
import chatHeaderPin from 'common/assets/icons/files/chatHeaderPin.svg'
import searchClose from 'common/assets/icons/files/searchClose.svg'
import magnifier from 'common/assets/icons/files/magnifier.svg'
import arrowDown16 from 'common/assets/icons/files/arrowDown16.svg'
import arrowDownOrange from 'common/assets/icons/files/arrowDownOrange.svg'
import orders24 from 'common/assets/icons/files/orders24.svg'
import bag24 from 'common/assets/icons/files/bag24.svg'
import calendar24 from 'common/assets/icons/files/calendar24.svg'
import tags24 from 'common/assets/icons/files/tags24.svg'
import tag16 from 'common/assets/icons/files/tag16.svg'
import user24 from 'common/assets/icons/files/user24.svg'
import dockMobile from 'common/assets/icons/files/dock-mobile.svg'
import openmenu24 from 'common/assets/icons/files/openmenu.svg'
import computer16 from 'common/assets/icons/files/computer.svg'
import goToChat from 'common/assets/icons/files/go-to-chat.svg'
import somethingWentWrong from 'common/assets/icons/files/somethingWentWrong.svg'
import overview from 'common/assets/icons/files/overview.svg'
import rupis from 'common/assets/icons/files/rupis.svg'
import reports from 'common/assets/icons/files/reports.svg'
import people from 'common/assets/icons/files/people.svg'
import contacts from 'common/assets/icons/files/contacts.svg'
import bots from 'common/assets/icons/files/bots.svg'
import bolt from 'common/assets/icons/files/bolt.svg'
import broadcast from 'common/assets/icons/files/broadcast.svg'
import routing from 'common/assets/icons/files/routing.svg'
import autoRouting from 'common/assets/icons/files/auto_routing.svg'
import routingRules from 'common/assets/icons/files/routing_rules.svg'
import templateMenu from 'common/assets/icons/files/templates-menu.svg'
import apikey from 'common/assets/icons/files/integrations.svg'
import messageLogs from 'common/assets/icons/files/message-logs.svg'
import webHooks from 'common/assets/icons/files/webhooks.svg'
import reAssign20 from 'common/assets/icons/files/re-assign.svg'
import reAssignLight from 'common/assets/icons/files/reAssignLight.svg'
import markAsRead from 'common/assets/icons/files/markAsRead.svg'
import markAsUnread from 'common/assets/icons/files/markAsUnread.svg'
import mediaEmpty from 'common/assets/icons/files/media-empty.svg'
import trash from 'common/assets/icons/files/trash.svg'
import trashRed from 'common/assets/icons/files/trashRed.svg'
import trashBlack from 'common/assets/icons/files/trashBlack.svg'
import clock from 'common/assets/icons/files/clock.svg'
import apps from 'common/assets/icons/files/apps.svg'
import appsSelected from 'common/assets/icons/files/appsSelected.svg'
import broadcastcard from 'common/assets/icons/files/broadcastcard.svg'
import zokoLogo from 'common/assets/icons/files/zoko-logo.svg'
import gmailLogo from 'common/assets/icons/files/gmail-logo.svg'
import facebookLogo from 'common/assets/icons/files/facebook-logo.svg'
import add from 'common/assets/icons/files/add.svg'
import externalLink from 'common/assets/icons/files/external-link.svg'
import video from 'common/assets/icons/files/video.svg'
import successTick from 'common/assets/icons/files/success_tick.svg'
import optionsMore from 'common/assets/icons/files/optionsMore.svg'
import warningCircle from 'common/assets/icons/files/warning-circle.svg'
import whatsappLogoFill from 'common/assets/icons/files/whatsapp-logo.svg'
import whatsappGrey from 'common/assets/icons/files/whatsapp-grey.svg'
import magentoLogo from 'common/assets/icons/files/magento-logo.svg'
import shopifyLogo from 'common/assets/icons/files/shopify-logo.svg'
import greenCheckCircle from 'common/assets/icons/files/green-check-circle.svg'
import closeDark from 'common/assets/icons/files/closeDark.svg'
import eye from 'common/assets/icons/files/eye.svg'
import copy from 'common/assets/icons/files/copy.svg'
import copyGrey from 'common/assets/icons/files/copy-grey.svg'
import filterDocument from 'common/assets/icons/files/filter_document.svg'
import filterGif from 'common/assets/icons/files/filter_gif.svg'
import filterImage from 'common/assets/icons/files/filter_image.svg'
import filterVideo from 'common/assets/icons/files/filter_video.svg'
import darkGreenTick from 'common/assets/icons/files/dark-green-tick.svg'
import mediaPin from 'common/assets/icons/files/mapPin.svg'
import mediaDocument from 'common/assets/icons/files/mediaDocument.svg'
import mediaPicture from 'common/assets/icons/files/mediaPicture.svg'
import mediaPlay from 'common/assets/icons/files/mediaPlay.svg'
import addButtonPrimary from 'common/assets/icons/files/addButtonPrimary.svg'
import addButtonBlack from 'common/assets/icons/files/addButtonBlack.svg'
import infoDark from 'common/assets/icons/files/infoDark.svg'
import tickGreen from 'common/assets/icons/files/tickGreen.svg'
import pencilWhite from 'common/assets/icons/files/pencilWhite.svg'
import eyeClosed from 'common/assets/icons/files/eyeClosed.svg'
import agents from 'common/assets/icons/files/agents.svg'
import greenDot from 'common/assets/icons/files/dot-green.svg'
import redDot from 'common/assets/icons/files/dot-red.svg'
import availability from 'common/assets/icons/files/availability.svg'
import userRole from 'common/assets/icons/files/user-role.svg'
import integrations from 'common/assets/icons/files/PuzzlePiece.svg'
import flows from 'common/assets/icons/files/flows.svg'
import messengerLight from 'common/assets/icons/files/messengerLight.svg'
import tagBlack from 'common/assets/icons/files/tag-black.svg'
import tagBlue from 'common/assets/icons/files/tag-blue.svg'
import tagGreen from 'common/assets/icons/files/tag-green.svg'
import tagPurple from 'common/assets/icons/files/tag-purple.svg'
import tagRed from 'common/assets/icons/files/tag-red.svg'
import tagYellow from 'common/assets/icons/files/tag-yellow.svg'
import tagBrown from 'common/assets/icons/files/tag-brown.svg'
import tagGrey from 'common/assets/icons/files/tag-grey.svg'
import tagOrange from 'common/assets/icons/files/tag-orange.svg'
import zapPrimary from 'common/assets/icons/files/flowsTrigger.svg'
import clockDark from 'common/assets/icons/files/clockDark.svg'
import exitFlag from 'common/assets/icons/files/exitFlag.svg'
import ifCondition from 'common/assets/icons/files/ifCondition.svg'
import newContact from 'common/assets/icons/files/new-contact.svg'
import magnifierPlus from 'common/assets/icons/files/magnifierPlus.svg'
import clockClockwise from 'common/assets/icons/files/clockClockwise.svg'
import buttonMessage from 'common/assets/icons/files/button-message.svg'
import listMessage from 'common/assets/icons/files/list-message.svg'
import yellowPin from 'common/assets/icons/files/yellowPin.svg'
import documentLight from 'common/assets/icons/files/document-light.svg'
import imageLight from 'common/assets/icons/files/image-light.svg'
import videoLight from 'common/assets/icons/files/video-light.svg'
import bounceBack from 'common/assets/icons/files/bounce-back.svg'
import calendarWait from 'common/assets/icons/files/calendar-wait.svg'
import hourglass from 'common/assets/icons/files/hourglass.svg'
import agentChat from 'common/assets/icons/files/agent-chat.svg'
import duoChating from 'common/assets/icons/files/duo-chating.svg'
import hourglassLight from 'common/assets/icons/files/hourglass-light.svg'
import like from 'common/assets/icons/files/like.svg'
import dislike from 'common/assets/icons/files/dislike.svg'
import downloadWhite from 'common/assets/icons/files/download_white.svg'
import likeBlack from 'common/assets/icons/files/likeBlack.svg'
import arrowDownGrey from 'common/assets/icons/files/arrowDownGrey.svg'
import vitals from 'common/assets/icons/files/vitals.svg'
import chatPrimary from 'common/assets/icons/files/chat-primary.svg'
import externalLinkBlack from 'common/assets/icons/files/external-link-black.svg'
import duoChattingBlack from 'common/assets/icons/files/duo-chatting-black.svg'
import plusGrey from 'common/assets/icons/files/plus-grey.svg'
import removeTag from 'common/assets/icons/files/remove-tag.svg'
import backArrowGrey from 'common/assets/icons/files/backArrowGrey.svg'
import zoko from 'common/assets/icons/files/zoko.svg'
import zokoWaterMark from 'common/assets/icons/files/zoko-water-mark.svg'
import leftArrowGrey from 'common/assets/icons/files/leftArrowGrey.svg'
import starSelected from 'common/assets/icons/files/star-selected.svg'
import starUnselected from 'common/assets/icons/files/star-unselected.svg'
import circleTick from 'common/assets/icons/files/tick_circle.svg'
import circleClock from 'common/assets/icons/files/clock_circle.svg'
import circleClose from 'common/assets/icons/files/close_circle.svg'
import circleTrigger from 'common/assets/icons/files/trigger_circle.svg'
import lightContacts from 'common/assets/icons/files/contacts_light.svg'
import lightList from 'common/assets/icons/files/list_light.svg'
import lightPencil from 'common/assets/icons/files/pencil_light.svg'
import starBlackOutline from 'common/assets/icons/files/star-black-outline.svg'
import ratingsReviews from 'common/assets/icons/files/ratings-review.svg'
import moneyBag from 'common/assets/icons/files/money-bag.svg'
import bagPrimary from 'common/assets/icons/files/bag-primary.svg'
import clockPrimary from 'common/assets/icons/files/clock-primary.svg'
import shippingPrimary from 'common/assets/icons/files/shipping-primary.svg'
import cartCancelled from 'common/assets/icons/files/cart-cancelled.svg'
import exitCondition from 'common/assets/icons/files/exit-condition.svg'
import errorFilled from 'common/assets/icons/files/errorFilled.svg'
import outIconDark from 'common/assets/icons/files/outIconDark.svg'
import inIconDark from 'common/assets/icons/files/inIconDark.svg'
import refreshIconWhite from 'common/assets/icons/files/refreshIconWhite.svg'
import emailLight from 'common/assets/icons/files/email-light.svg'
import facebookRound from 'common/assets/icons/files/facebook-round.svg'
import fbLogoRound from 'common/assets/icons/files/fb_logo_round.png'
import instagramLogo from 'common/assets/icons/files/instagram.png'
import pin from 'common/assets/icons/files/pin.svg'
import whatsapp from 'common/assets/icons/files/whatsapp.svg'
import plusPrimary from 'common/assets/icons/files/plus-primary.svg'
import overviewActive from 'common/assets/icons/files/overview_active.svg'
import overviewGrey from 'common/assets/icons/files/overview_grey.svg'
import settingsGrey from 'common/assets/icons/files/settingsGrey.svg'
import dragButton from 'common/assets/icons/files/drag-button.svg'
import user from 'common/assets/icons/files/user.svg'
import email from 'common/assets/icons/files/email.svg'
import announce from 'common/assets/icons/files/announce.svg'
import broadCastSegment from 'common/assets/icons/files/broadcast-segment.svg'
import plusCircleLight from 'common/assets/icons/files/plus-circle-light.svg'
import shoppingCart from 'common/assets/icons/files/shopping-cart.svg'
import segment from 'common/assets/icons/files/segment.svg'
import caseSensitive from 'common/assets/icons/files/case-sensitive.svg'
import caseInsensitive from 'common/assets/icons/files/case-insensitive.svg'
import infoGray from 'common/assets/icons/files/info-gray.svg'
import uploadArrow from 'common/assets/icons/files/upload-arrow.svg'
import externalLinkGrey from 'common/assets/icons/files/external-link-grey.svg'
import genericTag from 'common/assets/icons/files/generic_tag.svg'
import searchGrey from 'common/assets/icons/files/search_grey.svg'
import startChat from 'common/assets/icons/files/start-chat.svg'
import genericSegment from 'common/assets/icons/files/generic_segment.svg'
import systemTagRound from 'common/assets/icons/files/system-tag-round.svg'
import shopifyTagRound from 'common/assets/icons/files/shopify-tag-round.svg'
import zokoTagRound from 'common/assets/icons/files/zoko-tag-round.svg'
import undo from 'common/assets/icons/files/undo.svg'
import optOutIcon from 'common/assets/icons/files/optOutIcon.svg'
import dataIconLight from 'common/assets/icons/files/dataIconLight.svg'
import peopleLight from 'common/assets/icons/files/peopleLight.svg'
import translate from 'common/assets/icons/files/translate.svg'
import closeOrange from 'common/assets/icons/files/close-orange.svg'
import font from 'common/assets/icons/files/font.svg'
import bold from 'common/assets/icons/files/bold.svg'
import italic from 'common/assets/icons/files/italic.svg'
import underline from 'common/assets/icons/files/underline.svg'
import linkGray from 'common/assets/icons/files/link-gray.svg'
import emojiGray from 'common/assets/icons/files/emoji-gray.svg'
import typeGray from 'common/assets/icons/files/type-gray.svg'
import web from 'common/assets/icons/files/web.svg'
import mobile from 'common/assets/icons/files/mobile.svg'
import code from 'common/assets/icons/files/code.svg'
import liveStatus from 'common/assets/icons/files/status-live.svg'
import metaLogo from 'common/assets/icons/files/metaLogo.png'
import errorTriangle from 'common/assets/icons/files/errorTriangle.svg'
import woocommerceLogo from 'common/assets/icons/files/woocommerce-logo.png'
import whatsappBlack from 'common/assets/icons/files/whatsapp-black.svg'
import newOrg from 'common/assets/icons/files/newOrg.svg'
import zokoLogoWithName from 'common/assets/icons/files/zoko-logo-with-name.svg'
import unsupportedMessage from 'common/assets/icons/files/unsupported-message.svg'
import backArrowBlack from 'common/assets/icons/files/back-arrow-black.svg'
import flowHippo from 'common/assets/icons/files/flowhippo.svg'
import inactiveTick from 'common/assets/icons/files/inactiveTick.svg'
import catalog from 'common/assets/icons/files/catalog.svg'
import catalogArrow from 'common/assets/icons/files/catalogArrow.svg'
import catalogCart from 'common/assets/icons/files/catalog-cart.svg'
import stacks from 'common/assets/icons/files/stacks.svg'
import windows from 'common/assets/icons/files/windows.svg'
import playBlueIcon from 'common/assets/icons/files/play_blue_icon.svg'
import whatsAppFriendlyVideo from 'common/assets/icons/files/whatsapp_friendly_video.svg'
import appAll from 'common/assets/icons/files/app_all.svg'
import appProductivity from 'common/assets/icons/files/app_productivity.svg'
import appUtilities from 'common/assets/icons/files/app_utilities.svg'
import appMarketing from 'common/assets/icons/files/app_marketing.svg'
import appSupport from 'common/assets/icons/files/app_support.svg'
import appAi from 'common/assets/icons/files/app_ai.svg'
import appBroadcast from 'common/assets/icons/files/app_broadcast.png'
import appTemplates from 'common/assets/icons/files/app_templates.png'
import appQuickReplies from 'common/assets/icons/files/app_quick_replies.png'
import appPopUpCampaign from 'common/assets/icons/files/app_popup.png'
import appCtwa from 'common/assets/icons/files/app_ctwa.png'
import appClickToChat from 'common/assets/icons/files/app_click_to_chat.png'
import appAnalytics from 'common/assets/icons/files/app_analytics.png'
import appLinkCreator from 'common/assets/icons/files/app_link_creator.png'
import appRules from 'common/assets/icons/files/app_rules.png'
import appSegments from 'common/assets/icons/files/app_segments.png'
import appFlowHippo from 'common/assets/icons/files/app_flowhippo.png'
import appEnigma from 'common/assets/icons/files/app_enigma.png'
import enigma from 'common/assets/icons/files/enigma.png'
import enigmaTransparent from 'common/assets/icons/files/enigma_transparent.png'
import razorPay from 'common/assets/icons/files/razor_pay.png'
import chatGPT from 'common/assets/icons/files/chat-gpt.svg'
import partners from 'common/assets/icons/files/partners.svg'
import radio from 'common/assets/icons/files/radio.png'
import radioSelect from 'common/assets/icons/files/radio_selected.png'
import chatBot from 'common/assets/icons/files/chat-bot.svg'
import checkboxRound from 'common/assets/icons/files/checkbox-round.svg'
import orangeAdd from 'common/assets/icons/files/orange-add.svg'
import website from 'common/assets/icons/files/website.svg'
import cash from 'common/assets/icons/files/cash.svg'
import bell from 'common/assets/icons/files/bell.svg'
import catalogColoured from 'common/assets/icons/files/catalog-coloured.svg'
import whatsappGreyTile from 'common/assets/icons/files/ whatsapp-grey-tile.svg'
import facebookGreyTile from 'common/assets/icons/files/facebook_grey.png'
import instagramGreyTile from 'common/assets/icons/files/instagram_grey.png'
import addBlack from 'common/assets/icons/files/add-black.svg'
import strikeTick from 'common/assets/icons/files/strike-tick.png'
import hallo from 'common/assets/icons/files/hallo.svg'
import wismo from 'common/assets/icons/files/wismo.svg'
import sello from 'common/assets/icons/files/sello.svg'
import appHallo from 'common/assets/icons/files/app_hallo.png'
import appSello from 'common/assets/icons/files/app_sello.png'
import appWismo from 'common/assets/icons/files/app_wismo.png'
import appHalloLabel from 'common/assets/icons/files/app_hallo_label.png'
import appSelloLabel from 'common/assets/icons/files/app_sello_label.png'
import appWismoLabel from 'common/assets/icons/files/app_wismo_label.png'
import appHalloLarge from 'common/assets/icons/files/app_hallo_large.png'
import appWismoLarge from 'common/assets/icons/files/app_wismo_large.png'
import appSelloLarge from 'common/assets/icons/files/app_sello_large.png'
import appGuru from 'common/assets/icons/files/app_guru.png'
import appGuruLarge from 'common/assets/icons/files/app_guru_large.png'
import appGuruLabel from 'common/assets/icons/files/app_guru_label.png'
import chatGPTBasic from 'common/assets/icons/files/chat-gpt-basic.svg'
import closeRed from 'common/assets/icons/files/close-red.svg'
import tickOrange from 'common/assets/icons/files/tick-orange.svg'
import clockOrange from 'common/assets/icons/files/clock-orange.svg'
import closeCircleWhite from 'common/assets/icons/files/close-circle-white.svg'
import clipboard from 'common/assets/icons/files/clipboard.svg'
import revenue from 'common/assets/icons/files/revenue.svg'
import statistics from 'common/assets/icons/files/statistics.svg'
import statisticsGrey from 'common/assets/icons/files/statistics-grey.svg'
import arrowDownWhite from 'common/assets/icons/files/arrowDownWhite.svg'
import textSource from 'common/assets/icons/files/textSource.svg'
import websiteSource from 'common/assets/icons/files/websiteSource.svg'
import qnaSource from 'common/assets/icons/files/qnaSource.svg'
import uploadFile from 'common/assets/icons/files/upload_file.svg'
import guru from 'common/assets/icons/files/guru.svg'
import brain from 'common/assets/icons/files/brain.svg'
import webPage from 'common/assets/icons/files/web-page.svg'
import list from 'common/assets/icons/files/list.svg'
import tickLightGreen from 'common/assets/icons/files/tick-light-green.svg'
import whatsappCatalog from 'common/assets/icons/files/whatsapp-catalog.svg'
import shoppingBag from 'common/assets/icons/files/shopping-bag.svg'
import warningLight from 'common/assets/icons/files/warning-light.svg'
import facebookWhite from 'common/assets/icons/files/facebook-white.svg'
import fbLogoWhite from 'common/assets/icons/files/fbLogoWhite.svg'
import externalLinkWhite from 'common/assets/icons/files/externalLinkWhite.svg'
import warningTriangle from 'common/assets/icons/files/warning-triangle.svg'
import profilePlaceholder from 'common/assets/icons/files/profile-placeholder.svg'
import tagOutlineGrey from 'common/assets/icons/files/tag-outline-grey.svg'
import shopGrey from 'common/assets/icons/files/shop-grey.svg'
import locationGrey from 'common/assets/icons/files/location-grey.svg'
import internet from 'common/assets/icons/files/internet.svg'
import emailGrey from 'common/assets/icons/files/email-grey.svg'
import warningTriangleYellow from 'common/assets/icons/files/warning-triangle-yellow.svg'
import errorCircleRed from 'common/assets/icons/files/error-circle-red.svg'
import pencilWhiteBold from 'common/assets/icons/files/pencilWhiteBold.svg'
import rotateLeft from 'common/assets/icons/files/rotate-left.svg'
import purpleShine from 'common/assets/icons/files/purple-shine.svg'
import aiAssistantRound from 'common/assets/icons/files/ai-assistant-round.svg'
import aiAssistant from 'common/assets/icons/files/ai-assistant.svg'

const icons = {
  arrowElbowDownLeft,
  alt,
  calendar,
  caretDown,
  caretDownLight,
  circleLoading,
  chatBubble,
  chatBubbleOutline,
  chatBubbleOutlineSelected,
  chatBalloon,
  clip,
  clipLight,
  close,
  closeChat,
  document,
  dot,
  dots,
  dotsCircle,
  dragDots,
  emoji,
  unknownFile,
  filter,
  iconPlaceholder,
  image,
  info,
  instagram,
  leftArrow,
  rightArrow,
  lessCircle,
  lessCircleLight,
  link,
  location,
  logo,
  magnifierOutline,
  microphone,
  microphoneLight,
  morePhotos,
  notes,
  notesLight,
  online,
  offline,
  orders,
  panelIndicator,
  pencil,
  pinOutline,
  play,
  playLight,
  playDark,
  plus,
  plusOutline,
  plusOutlineWhite,
  plusCircle,
  phone,
  queue,
  queueOutline,
  queueOutlineSelected,
  quickReply,
  recentClock,
  sendToChat,
  search,
  send,
  sendWhite,
  sendAudio,
  settings,
  shortcut,
  smileOutline,
  smileLightOutline,
  squares,
  storeBag,
  stopLight,
  tag,
  tagBold,
  threeDots,
  type,
  typeLight,
  users,
  stop,
  xCircle,
  xCircleLight,
  zap,
  checkCircle,
  whiteClose,
  errorCircle,
  zapWhite,
  zapLight,
  read,
  delivered,
  accepted,
  picture,
  dotMenu,
  closeGrey,
  tick,
  templates,
  download,
  downloadOutline,
  productListEmpty,
  xPurple,
  xBlue,
  xBrown,
  addAddress,
  newTag,
  tickAccent,
  tickBlack,
  dockClose,
  dockOpen,
  chatHeaderPin,
  searchClose,
  magnifier,
  arrowDown16,
  arrowDownOrange,
  orders24,
  bag24,
  calendar24,
  tags24,
  tag16,
  user24,
  dockMobile,
  openmenu24,
  computer16,
  goToChat,
  somethingWentWrong,
  overview,
  rupis,
  reports,
  people,
  contacts,
  bots,
  bolt,
  broadcast,
  routing,
  autoRouting,
  routingRules,
  templateMenu,
  apikey,
  messageLogs,
  webHooks,
  reAssign20,
  reAssignLight,
  markAsRead,
  markAsUnread,
  mediaEmpty,
  trash,
  trashRed,
  trashBlack,
  clock,
  apps,
  appsSelected,
  broadcastcard,
  zokoLogo,
  gmailLogo,
  facebookLogo,
  add,
  externalLink,
  video,
  successTick,
  optionsMore,
  warningCircle,
  whatsappLogoFill,
  whatsappGrey,
  magentoLogo,
  shopifyLogo,
  greenCheckCircle,
  closeDark,
  eye,
  copy,
  copyGrey,
  filterDocument,
  filterGif,
  filterImage,
  filterVideo,
  darkGreenTick,
  mediaPin,
  mediaDocument,
  mediaPicture,
  mediaPlay,
  addButtonPrimary,
  addButtonBlack,
  infoDark,
  tickGreen,
  pencilWhite,
  eyeClosed,
  agents,
  greenDot,
  redDot,
  availability,
  userRole,
  integrations,
  flows,
  messengerLight,
  tagBlack,
  tagBlue,
  tagGreen,
  tagPurple,
  tagRed,
  tagYellow,
  tagBrown,
  tagGrey,
  tagOrange,
  zapPrimary,
  clockDark,
  exitFlag,
  ifCondition,
  newContact,
  magnifierPlus,
  clockClockwise,
  buttonMessage,
  listMessage,
  yellowPin,
  documentLight,
  imageLight,
  videoLight,
  bounceBack,
  calendarWait,
  hourglass,
  agentChat,
  duoChating,
  hourglassLight,
  like,
  dislike,
  downloadWhite,
  likeBlack,
  arrowDownGrey,
  vitals,
  chatPrimary,
  externalLinkBlack,
  duoChattingBlack,
  plusGrey,
  removeTag,
  backArrowGrey,
  zoko,
  zokoWaterMark,
  leftArrowGrey,
  starSelected,
  starUnselected,
  circleTick,
  circleClock,
  circleClose,
  circleTrigger,
  lightContacts,
  lightList,
  lightPencil,
  starBlackOutline,
  ratingsReviews,
  moneyBag,
  bagPrimary,
  clockPrimary,
  shippingPrimary,
  cartCancelled,
  exitCondition,
  errorFilled,
  outIconDark,
  inIconDark,
  refreshIconWhite,
  emailLight,
  facebookRound,
  fbLogoRound,
  instagramLogo,
  pin,
  whatsapp,
  plusPrimary,
  overviewActive,
  overviewGrey,
  settingsGrey,
  dragButton,
  user,
  email,
  announce,
  broadCastSegment,
  plusCircleLight,
  shoppingCart,
  segment,
  caseSensitive,
  caseInsensitive,
  infoGray,
  uploadArrow,
  externalLinkGrey,
  genericTag,
  searchGrey,
  startChat,
  genericSegment,
  systemTagRound,
  shopifyTagRound,
  zokoTagRound,
  undo,
  optOutIcon,
  dataIconLight,
  peopleLight,
  translate,
  closeOrange,
  font,
  bold,
  italic,
  underline,
  linkGray,
  emojiGray,
  typeGray,
  web,
  mobile,
  code,
  liveStatus,
  metaLogo,
  errorTriangle,
  woocommerceLogo,
  whatsappBlack,
  newOrg,
  zokoLogoWithName,
  unsupportedMessage,
  backArrowBlack,
  flowHippo,
  inactiveTick,
  catalog,
  catalogArrow,
  catalogCart,
  stacks,
  windows,
  playBlueIcon,
  whatsAppFriendlyVideo,
  appAll,
  appProductivity,
  appUtilities,
  appMarketing,
  appSupport,
  appAi,
  appBroadcast,
  appTemplates,
  appQuickReplies,
  appPopUpCampaign,
  appCtwa,
  appClickToChat,
  appAnalytics,
  appLinkCreator,
  appRules,
  appSegments,
  appFlowHippo,
  appEnigma,
  appHallo,
  appSello,
  appWismo,
  enigma,
  enigmaTransparent,
  razorPay,
  chatGPT,
  partners,
  radio,
  radioSelect,
  chatBot,
  checkboxRound,
  orangeAdd,
  website,
  cash,
  bell,
  catalogColoured,
  whatsappGreyTile,
  facebookGreyTile,
  addBlack,
  instagramGreyTile,
  strikeTick,
  hallo,
  wismo,
  sello,
  appHalloLabel,
  appSelloLabel,
  appWismoLabel,
  appSelloLarge,
  appHalloLarge,
  appWismoLarge,
  chatGPTBasic,
  appGuru,
  appGuruLabel,
  appGuruLarge,
  closeRed,
  tickOrange,
  clockOrange,
  closeCircleWhite,
  clipboard,
  revenue,
  statistics,
  statisticsGrey,
  arrowDownWhite,
  textSource,
  websiteSource,
  qnaSource,
  uploadFile,
  guru,
  brain,
  webPage,
  list,
  tickLightGreen,
  whatsappCatalog,
  shoppingBag,
  warningLight,
  facebookWhite,
  fbLogoWhite,
  externalLinkWhite,
  warningTriangle,
  profilePlaceholder,
  tagOutlineGrey,
  shopGrey,
  locationGrey,
  internet,
  emailGrey,
  warningTriangleYellow,
  errorCircleRed,
  pencilWhiteBold,
  rotateLeft,
  purpleShine,
  aiAssistantRound,
  aiAssistant,
}

export default icons
