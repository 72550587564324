import dayjs, { Dayjs } from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isToday from 'dayjs/plugin/isToday'

export const ZOKO_COLLECTIONS = {
  internal_health: 'internal_health',
  health_log: 'health_log',
  version_manager: 'version_manager',
  top_banner: 'top_banner',
  session_log: 'session_log',
  blocked_users: 'blocked_users',
}

const generateDates = () => {
  dayjs.extend(advancedFormat)
  dayjs.extend(customParseFormat)
  const dates = []
  const currentDate = dayjs()
  for (let i = 0; i < 7; i++) {
    dates.push(currentDate.subtract(i, 'days'))
  }
  return dates
}

export const generateTimeBlocks = (selectedDate: string): string[] => {
  dayjs.extend(isToday)
  const theDay = dayjs(selectedDate, FILTER_DATE_FORMAT)
  window.console.log(
    'Admin: generateTimeBlocks: selectedDate',
    selectedDate,
    theDay.format('YYYY-MM-DD HH:mm')
  )
  const today = theDay.isToday()
  let startTime: Dayjs = theDay.endOf('day')
  if (today) {
    startTime = startTime
      .set('hours', dayjs().get('hours'))
      .set('minutes', dayjs().get('minutes'))
  }
  const endTime = theDay.startOf('day')
  const slots = []
  while (startTime.isAfter(endTime)) {
    slots.push(startTime.format(ADMIN_LOCAL_FORMAT))
    startTime = startTime.subtract(1, 'minute')
  }
  return slots
}

export const FILTER_DATES = generateDates()
export const FILTER_DATE_FORMAT = 'YYYYMMDD'
export const ADMIN_LOCAL_FORMAT = 'YYYYMMDDHHmm'

export const ERROR_CODES = {
  ZOKO_SEND_API_FAILURE: 'ZOKO_SEND_API_FAILURE',
  ZOKO_CHAT_READ_API_FAILURE: 'ZOKO_CHAT_READ_API_FAILURE',
  WHATSAPP_API_DELAY: 'WHATSAPP_API_DELAY',
}

export const ADMIN_BLOCK_LISTING_COUNT = 10

export const ADMIN_ACCESS = [
  'arjun@zoko.io',
  'biswas@zoko.io',
  'aromal@zoko.io',
  'adhil@zoko.io',
  'abhai@zoko.io',
  'sachin@zoko.io',
  'rini@zoko.io',
  'ajith@zoko.io',
  'arpit@zoko.io',
]

export const SUPPORT_ACCESS = [
  ...ADMIN_ACCESS,
  'chandu@zoko.io',
  'rahul.anand@zoko.io',
  'benn@zoko.io',
  'aaquib@zoko.io',
]
