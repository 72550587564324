import { createRoot } from 'react-dom/client'
import './lib/i18n/index'
import { HelmetProvider } from 'react-helmet-async'
import React from 'react'

import { ErrorBoundary } from 'common/UI/ErrorBoundary'
import { ThemeProvider } from 'common/contexts/themeContext/themeContext'
import initialize from 'lib/config/intializations'
import { useIsWhiteLabelled } from 'modules/Auth/store'
// import config from 'lib/config'
window['global'] = window

const container = document.getElementById('root')
const root = createRoot(container)
const App = React.lazy(() => import('./App'))

const ErrorCatcher = () => {
  const [isWhiteLabelled] = useIsWhiteLabelled()
  return (
    <ErrorBoundary componentName="MainApp" isWhiteLabelled={isWhiteLabelled}>
      {/* <React.StrictMode> */}
      <HelmetProvider>
        <App />
      </HelmetProvider>
      {/* </React.StrictMode> */}
    </ErrorBoundary>
  )
}

root.render(
  <ThemeProvider>
    <ErrorCatcher />
  </ThemeProvider>
)

initialize()
