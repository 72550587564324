import { createStore, defaults, createHook } from 'react-sweet-state'

import actions from './actions'
import initialState from './initialState'

defaults.devtools = true
const Store = createStore({
  name: 'ZOKO-settings-' + window.location.origin,
  initialState,
  actions,
})

export const useSelectedTab = createHook(Store, {
  selector: (state) => state.selectedTab,
})

export const useDeleteAccountData = createHook(Store, {
  selector: (state) => ({
    deleteAccountLoader: state.deleteAccountLoader,
    cancellationReason: state.cancellationReason,
    ...state.checkboxes,
  }),
})
