import { AuthState } from 'modules/Auth/store/interface'

import { META_CLOUD_CONNECT_STATUS } from './constants'

const initialState: AuthState = {
  hostInfo: {
    pageTitle: '',
  },
  /** @field {userData} as null | any */
  userData: null,
  currentStoreId: '',
  currentStoreType: null,
  agents: [],
  showViewAs: false,
  mqttConnected: false,
  isMobile: false,
  internetConnected: true,
  preset: {
    name: '',
    image: '',
  },
  storePreset: {
    name: '',
    image: '',
    billingCurrency: '',
  },
  avatarImageUploading: false,
  profileLoader: false,
  storeImageUploading: false,
  accountSettingsLoader: false,
  passwordResetRequested: false,
  authLoader: false,
  agentStatusLoader: false,
  // subscriptionPlan: {
  //   id: '',
  //   plan: '',
  //   code: '',
  //   description: '',
  //   frequency: '',
  //   currency: '',
  //   price: 0,
  //   payment_status: '',
  //   renew_on: 0,
  //   wallet_balance: 0,
  //   active: false,
  //   upcoming_plan: '',
  //   plan_expires_on: 0,
  // },
  // subscriptionPlanLoader: false,
  connectWhatsappLoader: false,
  connectWhatsappInitiated: false,
  accountStatus: {},
  accountStatusLoader: false,
  userForm: {
    name: '',
    phone: '',
    country: '',
    company: '',
    agree: false,
    newUserLoader: false,
    websiteError: null,
    phoneError: null,
  },
  catalogExpand: false,
  showCatalogExpand: false,
  phonePeTimeProgress: 0,
  matchedStore: '',
  metaConnectStatus: META_CLOUD_CONNECT_STATUS.INITIAL,
  showLitePlanOnboardingFlow: false,
  storeListUpdatedAt: 0,
}

export default initialState
