export const userDataMapper = (userData = {}) => ({
  email: userData?.email,
  agentId: userData?.id,
  name: userData?.name,
  image: userData?.image,
  defaultStore: userData?.default_store,
})

export const headerMapper = ({ storeId }) => {
  let headers = {
    includeAuthToken: true, // this will be replaced with latest x-auth-token by the fetcter in lib/
    'x-store-id': '',
  }
  if (storeId) {
    headers['x-store-id'] = storeId
  }
  return headers
}

export const hostInfoMapper = (hostInfo) => {
  return {
    domain: hostInfo?.domain,
    active: hostInfo?.active,
    logoUrl: hostInfo?.logo_url,
    termsUrl: hostInfo?.terms_url,
    policyUrl: hostInfo?.policy_url,
    pageTitle: hostInfo?.page_title,
    faviconUrl: hostInfo?.favicon_url,
    showBilling: hostInfo?.show_billing,
    settingsUrl: hostInfo?.settings_url,
    phoneLogoUrl: hostInfo?.phone_logo_url,
    loginPageGreeting: hostInfo?.login_page_greeting,
    loginPageImage: hostInfo?.login_page_image,
    links: hostInfo?.links,
  }
}

export const accountHealthMapper = ({ store_id, connections }) => ({
  storeID: store_id,
  connections: connections?.map(
    ({
      platform_tier,
      zoko_tier,
      max_unique_chat_count,
      account_health,
      account_status,
      platform,
      channel,
      limited_account,
      account_blocked,
      business_verified,
      used_unique_chat_count,
    }) => ({
      platformTier: platform_tier,
      zokoTier: zoko_tier,
      count: max_unique_chat_count,
      accountHealth: account_health,
      accountStatus: account_status,
      platform,
      channel,
      limitedAccount: limited_account,
      accountBlocked: account_blocked,
      businessVerified: business_verified,
      usedCount: used_unique_chat_count,
    })
  ),
})

export const whatsappConnectionErrorMapper = {
  WHATSAPP_ALREADY_USED: 'This phone number is already assosiated with a store',
}
