import config from 'lib/config'
import routes from 'lib/config/routes'
import { fetcher } from 'lib/fetcher'
import trackEvent from 'lib/trackEvent'
import { convertToGetParams } from 'lib/fetcher/helpers'

import { Headers, whatsAppProvidersType } from './interface'

export const newUserFormErrors = {
  website: {
    INVALID: 'Not a valid URL',
  },
  phone: {
    INVALID: 'Not a valid WhatsApp number',
  },
}

//Only these emails will have access to create new account page.
export const twerloEmailsWithSpecialAccess = [
  'tech@twerlo.com',
  'care@twerlo.com',
  'r5sh81kfs@mozmail.com',
]

//Only to these routes will the user be taken after auth if he is coming from this route
export const redirectURLWhiteList = [
  routes.SHOPIFY_STORE_MATCHER,
  routes.STORE_SWITCHER,
]

export const ZOKO_ONBOARDING_MESSAGE = 'Activate Zoko Account…'
export const ZOKO_ONBOARDING_MESSAGE_LITE_PLAN = 'Hello there!'

export const STORAGE_KEY_DEFAULT_STORE = 'default_store'

export const META_PAYMENT_STATUS_MODAL_KEY = 'payment_status_modal'

export const META_PAYMENT_STATUS_MODAL_VALUES = {
  default: 'default',
  left: 'left',
  minimized: 'minimized',
}

export const trackReditus = async (headers: Headers, source: string) => {
  try {
    const number = await getPhoneNumber(headers)
    if (number && window?.gr) {
      trackEvent(trackEvent.modules.auth, trackEvent.events.track_reditus, {
        number: 'XXXX' + number.slice(-4),
        source,
      })
      window.console.log('trackReditus', 'XXXX' + number.slice(-4))
      window.gr('track', 'conversion', { email: number })
    }
  } catch (error) {
    window.console.error('error', error)
  }
}

export const getPhoneNumber = async (headers: Headers) => {
  const resp = await fetcher(`${config.BASE_API}/integrations`, {
    headers,
  })
  return resp?.data?.whatsapp?.name?.replace(/[^0-9]/g, '')
}

export const META_CLOUD_CONNECT_STATUS = {
  INITIAL: '',
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'error',
}

export const isExistingUser = async (email: string) => {
  const url = `${config.BASE_API}/check-email?${convertToGetParams({
    email,
  })}`
  const existingResp = await fetcher(url)
  return existingResp?.data?.exists
}

export const whatsAppProviders: {
  provider360: whatsAppProvidersType
  providerCloud: whatsAppProvidersType
} = {
  provider360: '360dialog',
  providerCloud: 'zoko_cloud',
}
