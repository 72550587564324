import { icons } from 'common/UI/Icon'

export const CHANNEL_TYPES = {
  whatsapp: {
    value: 'whatsapp',
    icon: icons.whatsappLogoFill,
    label: 'WhatsApp',
    displayImg: icons.whatsapp,
    policyLink: 'https://www.whatsapp.com/legal/business-policy/?lang=en',
    greyTile: icons.whatsappGreyTile,
    showCopyIcon: true,
  },
  instagram: {
    value: 'instagram',
    icon: icons.instagram,
    label: 'Instagram',
    displayImg: icons.instagramLogo,
    policyLink:
      'https://developers.facebook.com/docs/messenger-platform/policy/policy-overview',
    greyTile: icons.instagramGreyTile,
    showCopyIcon: false,
  },
  facebook: {
    value: 'facebook',
    icon: icons.facebookRound,
    label: 'Facebook',
    displayImg: icons.fbLogoRound,
    policyLink:
      'https://developers.facebook.com/docs/messenger-platform/policy/policy-overview',
    greyTile: icons.facebookGreyTile,
    showCopyIcon: false,
  },
}

export const channelList = [
  {
    label: CHANNEL_TYPES.whatsapp.label,
    value: CHANNEL_TYPES.whatsapp.value,
    isChecked: true,
  },
  {
    label: CHANNEL_TYPES.facebook.label,
    value: CHANNEL_TYPES.facebook.value,
    isChecked: true,
  },
  {
    label: CHANNEL_TYPES.instagram.label,
    value: CHANNEL_TYPES.instagram.value,
    isChecked: true,
  },
]

export const lastMessageMapper = (message) => {
  // Got this mapper from chat api backend. Ask for the updated mapping when you see Unsupported message.
  switch (message.type) {
    case 'audio':
    case 'voice':
      return '🔊 Audio'
    case 'image':
      return '📷 Image'
    case 'video':
      return '🎥 Video'
    case 'document':
      return '📄 Document'
    case 'template':
      return 'Template'
    case 'buttonTemplate':
      return 'Template'
    case 'richTemplate':
      return 'Template'
    case 'interactive_button':
      return 'Interactive Button'
    case 'interactive_list':
      return 'Interactive List'
    case 'catalog_product':
      return '📦 Product'
    case 'catalog_product_list':
      return '🗒️ Product List'
    case 'order':
      return '🛒 Cart'
    case 'order_details':
      return '💳 Payment Request'
    case 'order_status':
      return '🔖 Order Status Update'
    default:
      return message.text || 'Unsupported message'
  }
}
