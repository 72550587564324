import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react'

import config from 'lib/config'

Bugsnag?.start({
  apiKey: config.BUGSNAG_API_KEY,
  appVersion: 'v3.9.7',
  plugins: [new BugsnagPluginReact()],
  releaseStage: config.ENV,
})

export const BugsnagErrorBoundary =
  Bugsnag.getPlugin('react').createErrorBoundary(React)
