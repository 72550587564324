import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { Loading } from '../Loading'
import styles from './Button.module.scss'

export const Button = (props) => {
  const {
    colorScheme = 'primary',
    variant = 'solid',
    size = 'md',
    isDisabled = false,
    fluid = false,
    loading,
    children,
    selected = false,
    className = '',
    ...rest
  } = props

  const buttonClass = classNames(styles.buttonBase, {
    [styles[colorScheme]]: colorScheme,
    [styles[variant]]: variant,
    [styles[size]]: size,
    [styles.disabled]: isDisabled || loading,
    [styles[fluid ? 'fluid' : '']]: fluid,
    [styles.selected]: selected,
    [className || '']: className,
  })

  return !loading ? (
    <button className={buttonClass} disabled={isDisabled} {...rest}>
      {children}
    </button>
  ) : (
    <div className={buttonClass}>
      <Loading variant="circle" />
    </div>
  )
}

Button.propTypes = {
  colorScheme: PropTypes.oneOf([
    'primary',
    'inverted',
    'secondary',
    'tertiary',
    'negative',
  ]),
  variant: PropTypes.oneOf(['solid', 'outline', 'ghost', 'text']),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  isDisabled: PropTypes.bool,
  fluid: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.node,
  selected: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
}
