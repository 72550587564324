import localforage from 'localforage'

import config from 'lib/config'
import routes from 'lib/config/routes'
import { fetcher } from 'lib/fetcher'
import logError from 'lib/logError'
import trackEvent from 'lib/trackEvent'
import { deepClone } from 'lib/utils/object'
import { STORETYPES } from 'modules/Settings/pages/Integrations/constants'
import { CHANNEL_TYPES } from 'lib/utils/channel'

import initialState from './initialState'
import {
  accountHealthMapper,
  headerMapper,
  hostInfoMapper,
  userDataMapper,
  whatsappConnectionErrorMapper,
} from './mapper'
import {
  META_CLOUD_CONNECT_STATUS,
  STORAGE_KEY_DEFAULT_STORE,
  trackReditus,
  whatsAppProviders,
} from './constants'
import { kickOutUser } from '../Authenticator/helper'

const actions = {
  getHostInfo:
    ({ hostName, service }) =>
    async ({ setState }) => {
      try {
        const resp = await fetcher(
          `${config.BASE_API}/domainsettings/${hostName}`
        )

        const hostInfo = resp?.data
        setState({
          hostInfo: hostInfoMapper(hostInfo),
        })
      } catch (e) {
        logError(e, 'getHostInfo')
        service.toast('Failed to fetch store info', { type: 'error' })
      }
    },
  updateUserData:
    (preferredStore) =>
    async ({ setState, dispatch, getState }) => {
      const _preferredStore =
        preferredStore || (await localforage.getItem(STORAGE_KEY_DEFAULT_STORE))
      try {
        const headers = headerMapper({ storeId: null })
        const userDataResp = await fetcher(`${config.BASE_API}/user`, {
          headers,
          params: {
            preferred_store: _preferredStore || null,
          },
        })
        if (!userDataResp.data || !userDataResp.ok) {
          if (userDataResp?.data?.error === 'Unauthorized') {
            return kickOutUser()
          }
          return window.globals.service.toast('Something went wrong', {
            type: 'error',
            id: 'user_api',
            button: {
              label: 'REFRESH',
              onClick: async () => {
                window.location.reload()
              },
            },
          })
        }
        let userData = userDataMapper(userDataResp?.data)
        if (userData?.defaultStore) {
          localforage.setItem(STORAGE_KEY_DEFAULT_STORE, userData?.defaultStore)
        }
        const list = deepClone(
          userDataResp?.data?.stores?.filter(
            (store) => store.id !== '00000000-0000-0000-0000-000000000000' //TODO: temporary fix, backend should not remove this
          ) || []
        )
        window.globals.storeList = list.map((store) => {
          return {
            ...store,
            phone: store?.integrations?.find?.(
              (integration) =>
                integration?.platform_type === CHANNEL_TYPES?.whatsapp?.value
            )?.platform_key,
          }
        })
        setTimeout(() => {
          setState({
            userData,
            currentStoreId: userDataResp?.data?.default_store,
            // storeList: deepClone(
            //   userDataResp?.data?.stores?.filter(
            //     (store) => store.id !== '00000000-0000-0000-0000-000000000000' //TODO: temporary fix, backend should not remove this
            //   ) || []
            // ),
            storeListUpdatedAt: Date.now(),
          })
        }, 100)

        //updateUserData is being called from billing also.
        //If there is history params that means its after login/signup
        //Only here this routing need to happen
        const newUserNotLinkedToLead = getState()?.userForm?.id
        if (newUserNotLinkedToLead) {
          dispatch(
            actions.markLeadAsSignedUp({ headers, id: newUserNotLinkedToLead })
          )
        }
      } catch (error) {
        logError(error, 'updateUserData')
      }
    },
  getStoreType:
    ({ storeId }) =>
    async ({ setState }) => {
      try {
        const authHeaders = headerMapper({
          storeId,
        })

        const resp = await fetcher(`${config.BASE_API}/integrations`, {
          headers: authHeaders,
          ignoreTracking: true,
        })

        // setState({
        //   currentStoreType: resp?.data?.shopify?.active
        //     ? STORETYPES.legacy
        //     : STORETYPES.ecomm,

        let currentStoreType = ''
        if (resp?.data?.ecommerce?.length) {
          currentStoreType = STORETYPES.ecomm
        } else if (resp?.data?.shopify?.active) {
          currentStoreType = STORETYPES.legacy
        }
        setState({
          currentStoreType,
          currentStoreTypeForProductsTab:
            currentStoreType === STORETYPES.ecomm &&
            !resp?.data?.shopify?.active
              ? STORETYPES.ecomm
              : resp?.data?.shopify?.active
              ? STORETYPES.legacy
              : '',
        })
      } catch (error) {
        logError(error, 'updateStoreType')
      }
    },
  setCurrentStore:
    ({ headers, storeId, redirect }) =>
    async () => {
      const requestBody = {
        default_store: storeId,
      }
      try {
        const resp = await fetcher(`${config.BASE_API}/user`, {
          headers,
          method: 'POST',
          body: requestBody,
        })

        if (resp?.data?.success) {
          window.globals.service.toast('Switching organization', {
            type: 'success',
          })
          localforage.setItem(STORAGE_KEY_DEFAULT_STORE, storeId)
          setTimeout(() => {
            if (redirect) {
              window.location.href = redirect
            } else {
              window.location.reload()
            }
          }, 500)
        } else {
          window.globals.service.toast('Failed to switch store. Check access', {
            type: 'error',
          })
        }
      } catch (error) {
        logError(error, 'setCurrentStore')
        window.globals.service.toast('Failed to set default store', {
          type: 'error',
        })
      }
    },
  registerFCMToken:
    ({ token }) =>
    async () => {
      try {
        const requestBody = {
          client_token: token,
        }

        const resp = await fetcher(
          `${config.BASE_API}/notifications/subscribe`,
          {
            headers: {
              includeAuthToken: true,
            },
            method: 'POST',
            body: requestBody,
          }
        )

        window.console.log(resp)
      } catch (error) {
        logError(error, 'setCurrentStore')
        window.globals.service.toast('Failed to setup notification', {
          type: 'error',
        })
      }
    },
  showViewAs:
    (showViewAs) =>
    ({ setState }) => {
      setState({
        showViewAs,
      })
    },
  updateMqttConnection:
    (mqttConnected) =>
    ({ setState }) => {
      setState({
        mqttConnected,
      })
    },
  setMobile:
    (isMobile) =>
    ({ setState }) =>
      setState({ isMobile }),
  updateInternetStatus:
    (internetConnected) =>
    ({ setState }) => {
      if (!internetConnected) {
        window.globals?.service?.toast(
          'You are offline. Please check your connection',
          {
            id: 'offlineMessage',
            type: 'error',
            persist: true,
          }
        )
      }
      setState({
        internetConnected,
      })
    },
  updateUserProfile:
    ({ name, image_url }) =>
    ({ getState, setState }) =>
      setState({
        userData: { ...getState().userData, name: name, image: image_url },
      }),
  setPreset:
    () =>
    ({ getState, setState }) => {
      const { name, image, dataUri } = getState().userData
      setState({
        preset: {
          name,
          image,
          dataUri,
        },
      })
    },
  updateProfile:
    ({ headers }) =>
    async ({ setState, getState }) => {
      setState({
        profileLoader: true,
      })
      try {
        const { name, image } = getState()?.preset
        const resp = await fetcher(`${config.BASE_API}/profile`, {
          headers,
          body: {
            name: name,
            image_url: image,
          },
          method: 'PUT',
        })
        if (resp.data) {
          window?.globals?.service.toast(
            'Successfully updated agent information',
            {
              type: 'success',
            }
          )
          setState({
            userData: {
              ...getState().userData,
              name: name,
              image: image,
            },
          })
        } else {
          throw new Error('Invalid response')
        }
      } catch (error) {
        logError(error, 'updateProfile')
        window?.globals?.service.toast('Failed to update agent information', {
          type: 'error',
        })
      } finally {
        setState({
          profileLoader: false,
        })
      }
    },
  setName:
    (name) =>
    ({ getState, setState }) => {
      const { image } = getState().preset
      setState({
        preset: {
          name: name,
          image: image,
        },
      })
    },
  setImage:
    (image) =>
    ({ getState, setState }) => {
      const { name } = getState().preset
      setState({
        preset: {
          name: name,
          image: image,
        },
      })
    },
  setStorePreset:
    (currentStore) =>
    ({ setState }) => {
      setState({
        storePreset: {
          name: currentStore?.name || '',
          image: currentStore?.image || '',
        },
      })
    },
  updateAccount:
    ({ headers }) =>
    async ({ setState, getState, dispatch }) => {
      setState({
        accountSettingsLoader: true,
      })
      try {
        const { name, image } = getState()?.storePreset
        const resp = await fetcher(`${config.BASE_API}/store-settings`, {
          headers,
          body: {
            name: name,
            image_url: image,
          },
          method: 'PUT',
        })
        if (resp.data) {
          window?.globals?.service.toast(
            'Successfully updated organization information',
            {
              type: 'success',
            }
          )
          // Replace current store name and image
          dispatch(actions.updateUserData())
          // storeList update
          // setState({
          //   storeList: storeList,
          // })
        } else {
          throw new Error('Invalid response')
        }
      } catch (error) {
        logError(error, 'updateProfile')
        window?.globals?.service.toast(
          'Failed to update organization information',
          {
            type: 'error',
          }
        )
      } finally {
        setState({
          accountSettingsLoader: false,
        })
      }
    },
  setStoreName:
    (name) =>
    ({ getState, setState }) => {
      setState({
        storePreset: {
          ...getState().storePreset,
          name: name,
        },
      })
    },
  setbillingCurrency:
    (billingCurrency) =>
    ({ setState, getState }) => {
      setState({
        storePreset: {
          ...getState().storePreset,
          billingCurrency,
        },
      })
    },
  setStoreImage:
    (image) =>
    ({ getState, setState }) => {
      setState({
        storePreset: {
          ...getState().storePreset,
          image: image,
        },
      })
    },
  setAvatarUploading:
    (avatarImageUploading) =>
    ({ setState }) => {
      setState({
        avatarImageUploading,
      })
    },
  setStoreImageUploading:
    (storeImageUploading) =>
    ({ setState }) => {
      setState({
        storeImageUploading,
      })
    },
  setPasswordRequested:
    (passwordResetRequested) =>
    ({ setState }) => {
      setState({
        passwordResetRequested,
      })
    },
  setAuthLoader:
    (flag) =>
    ({ setState }) => {
      setState({
        authLoader: flag,
      })
    },
  clearUserData:
    () =>
    ({ setState, getState }) => {
      setState({
        ...initialState,
        hostInfo: getState().hostInfo,
      })
    },
  /**
    @params args {headers: any, agentId: string, available: boolean}
  */
  toggleAgentStatus:
    (args) =>
    async ({ setState, dispatch }) => {
      const { agentId, headers, available } = args
      setState({
        agentStatusLoader: true,
      })
      try {
        const resp = await fetcher(
          `${config.BASE_API}/agent/${agentId}/available/${available}`,
          {
            headers,
            method: 'PUT',
          }
        )
        if (resp?.data?.success) {
          dispatch(actions.updateUserData())
          // storeList update
          // setState({
          //   storeList: deepClone(
          //     getState().storeList.map((storeData) => {
          //       if (storeData?.id === currentStoreId) {
          //         storeData.available = available
          //       }
          //       return storeData
          //     })
          //   ),
          // })
        } else {
          throw new Error('Invalid response')
        }
      } catch (e) {
        logError(e, 'toggleAgentStatus')
        window?.globals?.service?.toast('Failed to update availability', {
          type: 'error',
        })
      } finally {
        setState({
          agentStatusLoader: false,
        })
      }
    },
  getAgentStatus:
    ({ headers }) =>
    async ({ setState, dispatch }) => {
      setState({
        agentStatusLoader: true,
      })
      try {
        const resp = await fetcher(`${config.BASE_API}/agent-availability`, {
          headers,
        })
        if (resp?.data) {
          dispatch(actions.updateUserData())
          // storeList update
          // setState({
          //   storeList: deepClone(
          //     getState().storeList.map((storeData) => {
          //       if (storeData?.id === currentStoreId) {
          //         storeData.available = Available
          //       }
          //       return storeData
          //     })
          //   ),
          // })
        } else {
          throw new Error('Invalid response')
        }
      } catch (e) {
        logError(e, 'getAgentStatus')
      } finally {
        setState({
          agentStatusLoader: false,
        })
      }
    },
  getSubscription:
    ({ headers }) =>
    async ({ setState }) => {
      setState({
        subscriptionPlanLoader: true,
      })
      try {
        const resp = await fetcher(`${config.BASE_API}/billing/subscription`, {
          headers,
        })
        setState({
          subscriptionPlan: resp?.data,
        })
      } catch (err) {
        logError(err, 'getSubscription')
      } finally {
        setState({
          subscriptionPlanLoader: false,
        })
      }
    },
  forgotPassword:
    ({ email }) =>
    async ({ setState }) => {
      setState({
        authLoader: true,
      })
      try {
        const resp = await fetcher(`${config.BASE_API}/user/reset-password`, {
          body: {
            email,
          },
        })
        if (resp?.data?.success) {
          window.console.log('Forgot password', resp?.data)
          setState({
            passwordResetRequested: true,
          })
        } else {
          window.console.error('Forgot password: invalid response', resp?.data)
          throw new Error('Invalid response')
        }
      } catch (e) {
        window.console.error('Forgot password: catch', e)
        logError(e, 'forgotPassword')
        window?.globals?.service?.toast('Failed to request forgot password', {
          type: 'error',
        })
      } finally {
        setState({
          authLoader: false,
        })
      }
    },
  onOrgCreate:
    ({ history, partner, provider = whatsAppProviders.providerCloud }) =>
    async ({ getState, setState, dispatch }) => {
      window.console.log('onOrgCreate', { provider })
      window?.globals?.service?.toast(
        'Creating new organization. Please wait..',
        {
          id: 'new-org',
        }
      )
      setState({
        orgLoader: true,
      })
      const { storePreset } = getState()
      try {
        const headers = headerMapper({ storeId: null })
        const resp = await fetcher(`${config.BASE_API}/store/create`, {
          method: 'POST',
          headers,
          body: {
            name: storePreset?.name,
            billing_currency: storePreset?.billingCurrency || 'USD',
            partner,
            // preferred_plan: plan,
            provider: provider || whatsAppProviders.providerCloud,
          },
        })
        if (resp?.ok) {
          window?.globals?.service?.toast('Organization created successfully', {
            type: 'success',
            id: 'new-org',
          })
          if (resp?.data?.store_id) {
            await localforage.setItem(
              STORAGE_KEY_DEFAULT_STORE,
              resp?.data?.store_id
            )
          }
          await dispatch(actions.updateUserData(resp?.data?.store_id))
          window.console.log('PROVIDER', provider)
          if (provider === whatsAppProviders.provider360) {
            history.replace(routes.CONNECT_WHATSAPP)
          } else {
            history.replace(routes.CONNECT_META_CLOUD, {
              provider,
            })
          }
          setState({
            orgLoader: false,
          })
        } else {
          throw new Error('Invalid response')
        }
      } catch (e) {
        logError(e, 'onOrgCreate')
        window?.globals?.service?.toast('Failed to create organization', {
          type: 'error',
        })
        setState({
          orgLoader: false,
        })
      }
    },
  closeWhatsappQRPopup:
    ({ message, headers, assignChatToCurrentUser }) =>
    async ({ dispatch, getState }) => {
      const { currentStoreId } = getState()

      const currentStore = window.globals?.storeList?.find(
        (store) => store.id === currentStoreId
      )

      const whatsappIntegration = currentStore?.integrations?.find(
        (item) => item?.platform_type === 'whatsapp'
      )

      if (
        currentStore &&
        whatsappIntegration?.platform_key &&
        currentStore?.onboarding_qr_closed === false
      ) {
        try {
          const resp = await fetcher(
            `${config.BASE_API}/store/close-onboarding-qr`,
            {
              headers,
              method: 'PUT',
            }
          )

          dispatch(actions.updateUserData())
          // storeList update
          // setState({
          //   storeList: storeList?.map((store) => {
          //     if (store.id === currentStoreId) {
          //       store.onboarding_qr_closed = true
          //     }
          //     return store
          //   }),
          // })

          if (resp?.ok) {
            if (message) {
              trackEvent(
                trackEvent.modules.auth,
                trackEvent.events.onboarding_qr_scanned
              )
            }

            dispatch(actions.updateUserData())
            dispatch(actions.getAccountStatus({ headers }))
            assignChatToCurrentUser?.({
              customerId: message?.payload?.customer?.id,
              message,
              customerPhone: message?.payload?.customer?.id_on_platform,
            })

            // storeList update
            // setState({
            //   storeList: getState()?.map((store) => {
            //     if (store.id === currentStoreId) {
            //       return { ...store, onboarding_qr_closed: true }
            //     }
            //     return store
            //   }),
            // })
          } else {
            throw new Error('Invalid response')
          }
        } catch (err) {
          logError(err, 'closeWhatsappQRPopup')
        }
      }
    },
  setConnectWhatsappLoader:
    (connectWhatsappLoader) =>
    ({ setState }) =>
      setState({
        connectWhatsappLoader,
      }),
  setConnectionInitiated:
    (connectWhatsappInitiated) =>
    ({ setState }) =>
      setState({
        connectWhatsappInitiated,
      }),
  connectWhatsapp:
    ({ headers, client, channel }) =>
    async ({ dispatch, setState, getState }) => {
      let resp
      try {
        trackEvent(
          trackEvent.modules.auth,
          trackEvent.events.whatsapp_connect_api_call
        )
        resp = await fetcher(
          `${config.BASE_API}/integrations/whatsapp/install`,
          {
            headers,
            body: {
              client,
              channel,
            },
            method: 'POST',
          }
        )

        if (resp?.ok) {
          await trackReditus(headers, 'connectWhatsapp')
          dispatch(actions.updateUserData())
        } else {
          dispatch(actions.setConnectionInitiated(false))
          window.globals?.service?.toast(
            whatsappConnectionErrorMapper?.[resp?.data?.description] ||
              'Failed to connect whatsapp',
            {
              type: 'error',
            }
          )

          throw new Error(resp?.data)
        }
        window.console.log(
          'connectWhatsapp',
          whatsappConnectionErrorMapper?.[resp?.data?.description]
        )
      } catch (e) {
        logError(e, 'connectWhatsapp', {
          error_response: JSON.stringify(resp?.data),
        })
        window.console.log('connectWhatsapp', e)
      } finally {
        setState({
          whatsapp: {
            ...getState()?.whatsapp,
            loader: false,
          },
        })
      }
    },
  getAccountStatus:
    ({ headers }) =>
    async ({ setState }) => {
      try {
        setState({
          accountStatusLoader: true,
        })
        const resp = await fetcher(`${config.BASE_API}/store/account/status`, {
          headers,
        })
        if (!resp?.data?.error) {
          setState({
            accountStatus: accountHealthMapper(resp?.data),
          })
        } else {
          window.globals.service.toast(resp?.data?.description, {
            type: 'error',
          })
          setState({
            tierData: {},
          })
        }
      } catch (err) {
        logError(err, 'getAccountStatus')
      } finally {
        setState({
          accountStatusLoader: false,
        })
      }
    },
  clearStoreName:
    () =>
    ({ setState }) =>
      setState({
        storePreset: deepClone(initialState.storePreset),
      }),
  /**
   *
   * @param {*} param0
   * @returns
   */
  submitNewUserForm:
    ({
      partner = '',
      partner_external_id,
      history,
      provider,
      nextRoute = routes.JOIN,
    }) =>
    async ({ getState, setState }) => {
      const { name, phone, company, country } = getState()?.userForm
      try {
        setState({
          userForm: {
            ...getState()?.userForm,
            newUserLoader: true,
          },
        })
        const resp = await fetcher(`${config.BASE_API}/signup/lead`, {
          body: {
            contact_name: name,
            contact_wa_number: phone,
            company_website: company,
            partner,
            partner_external_id,
            country_code: country?.toUpperCase(),
          },
        })

        if (resp?.ok) {
          if (resp?.data?.id) {
            setState({
              userForm: {
                id: resp?.data?.id,
              },
            })
          }
          setState({
            userForm: {
              ...getState()?.userForm,
              phoneError: resp?.data?.errors?.contact_wa_number,
              websiteError: resp?.data?.errors?.company_website,
            },
          })
          if (!resp?.data?.has_error) {
            history.replace(nextRoute, { provider })
          }
        } else {
          throw new Error('Invalid response')
        }
      } catch (err) {
        logError(err, 'submitNewUserForm')
      } finally {
        setState({
          userForm: {
            ...getState()?.userForm,
            newUserLoader: false,
          },
        })
      }
    },
  markLeadAsSignedUp:
    ({ id, headers }) =>
    async ({ setState }) => {
      try {
        const resp = await fetcher(
          `${config.BASE_API}/signup/lead/${id}/link-user`,
          {
            method: 'PATCH',
            headers,
          }
        )

        if (!resp?.ok) {
          throw new Error('Invalid response')
        } else {
          setState({
            userForm: deepClone(initialState.userForm),
          })
        }
      } catch (err) {
        logError(err, 'markLeadAsSignedUp')
      }
    },
  setNewUserFormData:
    (key, value) =>
    ({ getState, setState }) =>
      setState({
        userForm: {
          ...getState()?.userForm,
          [key]: value,
        },
      }),
  setCatalogExpand:
    (catalogExpand) =>
    ({ setState }) => {
      setState({
        catalogExpand,
      })
    },
  showCatalogExpand:
    (showCatalogExpand) =>
    ({ setState }) => {
      setState({
        showCatalogExpand,
      })
    },
  setMatchedStore:
    (matchedStore) =>
    ({ setState }) =>
      setState({ matchedStore }),
  setConnectCloudStatus:
    (metaConnectStatus) =>
    ({ setState }) =>
      setState({ metaConnectStatus }),
  /**
   *
   * @param {*} param0
   * @returns
   */
  metaConnectCloud:
    ({ headers, payload }) =>
    async ({ setState, dispatch }) => {
      setState({
        metaConnectStatus: 'pending',
      })
      try {
        const resp = await fetcher(
          `${config.BASE_API}/integrations/whatsapp/install/cloud`,
          {
            headers,
            body: payload,
          }
        )
        if (resp?.ok) {
          window?.globals?.service?.toast('Please wait...', {
            type: 'success',
            duration: 5,
          })
          setTimeout(() => {
            setState({
              metaConnectStatus: META_CLOUD_CONNECT_STATUS.SUCCESS,
            })
            dispatch(actions.updateUserData())
          }, 5000)
        } else {
          throw new Error('Invalid response')
        }
      } catch (e) {
        logError(e, 'metaConnectCloud')
        window?.globals?.service?.toast('Failed to connect whatsapp. Retry', {
          type: 'error',
        })
        setTimeout(() => {
          setState({
            metaConnectStatus: META_CLOUD_CONNECT_STATUS.ERROR,
          })
        }, 1000)
      }
    },
  setLitePlanOnboardingFlow:
    (showLitePlanOnboardingFlow) =>
    ({ setState }) =>
      setState({
        showLitePlanOnboardingFlow,
      }),
  addNumberToBillingNotifications:
    ({ headers, phone }) =>
    async ({ setState }) => {
      try {
        setState({
          addNumberToNotificationsLoader: true,
        })
        const notifications = await fetcher(
          `${config.BASE_API}/billing/notifications`,
          {
            headers,
          }
        )

        const resp = await fetcher(`${config.BASE_API}/billing/notifications`, {
          headers,
          body: {
            email: notifications?.data?.email || [],
            phone: [...(notifications?.data?.phone || []), phone],
            warning_threshold: notifications?.data?.warning_threshold,
          },
        })

        if (!resp?.ok) {
          throw new Error('Invalid response')
        }
      } catch (err) {
        logError(err, 'addNumberToBillingNotifications')
      } finally {
        setState({
          addNumberToNotificationsLoader: true,
        })
      }
    },
}

export default actions
