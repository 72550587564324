import config from 'lib/config'
import routes from 'lib/config/routes'
import { fetcher } from 'lib/fetcher'
import logError from 'lib/logError'

const actions = {
  changeSelectedTab:
    (selectedTab) =>
    ({ setState }) => {
      setState({
        selectedTab,
      })
    },
  updateCancellationReason:
    (cancellationReason) =>
    ({ setState }) =>
      setState({
        cancellationReason,
      }),
  updateAccountDeletionChecks:
    (field, value) =>
    ({ getState, setState }) =>
      setState({
        checkboxes: {
          ...getState().checkboxes,
          [field]: value,
        },
      }),
  deleteZokoAccount:
    ({ headers, history }) =>
    async ({ getState, setState }) => {
      setState({
        deleteAccountLoader: true,
      })
      try {
        const resp = await fetcher(`${config.BASE_API}/store`, {
          headers,
          method: 'DELETE',
          body: {
            timestamp: Date.now(),
            reason: getState().cancellationReason,
          },
        })

        if (resp?.ok) {
          window.globals.service?.toast('Store deleted successfully', {
            type: 'success',
          })
          history.push(routes.SIGNOUT)
        } else {
          throw new Error('Invalid response')
        }
      } catch (err) {
        logError(err, 'deleteZokoAccount')
        window.globals.service.toast('Failed to delete Zoko account', {
          type: 'error',
        })
      } finally {
        setState({
          deleteAccountLoader: false,
        })
      }
    },
}

export default actions
