import sizeof from 'object-sizeof'
import { useEffect, useState } from 'react'

import config from 'lib/config'
import { getAuthToken } from 'lib/firebase'
import logError from 'lib/logError'

import { convertToGetParams } from './helpers'

interface Options {
  body?: any
  params?: any
  headers?: any
  timeout?: boolean
  shouldCache?: boolean
  fileUpload?: boolean
  method?: string
  signal?: any
  [key: string]: any
}

interface Response {
  status?: number
  statusText?: string
  headers?: Headers
  body?: any
  [key: string]: any
  data?: any
  ok?: boolean
  isCached?: boolean
  duration?: number
}

const fetcher = async (
  url: string,
  options: Options = {},
  timeoutCallback?: () => void
): Promise<Response | void> => {
  let { body, params } = options
  let authToken = ''
  if (options?.headers?.includeAuthToken) {
    authToken = (await getAuthToken()) || ''
    if (authToken) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${authToken}`,
      }
      delete options.headers.includeAuthToken
    } else {
      options.headers.includeAuthToken = 'auth token not available'
    }
  }
  if (body && !options.fileUpload) {
    options.body = JSON.stringify(body)
    if (!options.method) {
      options.method = 'POST'
    }
  }

  if (options?.timeout) {
    delete options.timeout
    // @ts-ignore: Unreachable code error
    options.signal = AbortSignal.timeout(20000)
  }

  if (params) {
    params = convertToGetParams(params)
    let separator = '?'
    if (url.includes('?')) {
      separator = '&'
    }
    url = `${url}${separator}${params}`
  }

  options.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...options.headers,
  }

  const timer = Date.now()
  let api = url.split('?')?.[0]
  api = api.replace(`${config.BASE_API}`, '')

  let trackerMetaData = {
    method: options.method || 'GET',
    api,
    url,
    params: options.params || {},
    payload_size: sizeof(body || {}),
    header_size: sizeof(options.headers || {}),
    auth_token_size: authToken?.length,
  }

  if (options.shouldCache && window.globals.apiCache[url]) {
    const cachedResponse: Response = {
      data: window.globals.apiCache[url],
      duration: Date.now() - timer,
      isCached: true,
      ok: true,
    }
    return cachedResponse
  }

  return window
    .fetch(url, options)
    .then(async (resp: Response) => {
      if (options.fileUpload) {
        resp.data = {
          url: url.split('?')[0],
        }
        return resp
      }
      try {
        resp.data = await resp?.json?.()
      } catch (e) {
        window.console.log(e)
        window.console.error(
          'FETCHER_FAIL',
          'Invalid response',
          trackerMetaData
        )
      }
      return resp
    })
    .then((resp: Response) => {
      if (options.shouldCache) {
        window.globals.apiCache[url] = resp.data
      }
      resp.duration = Date.now() - timer
      const responseKeys = window.Array.isArray(resp?.data)
        ? `Array length: ${resp?.data?.length}`
        : Object.keys(resp?.data || {})
      try {
        const respSkeleton = {
          response_size: sizeof(resp?.data),
          duration: resp?.duration,
          statusCode: resp?.status,
          statusText: resp?.statusText,
          type: resp?.type,
          bodyUsed: resp?.bodyUsed,
          success: resp?.ok,
          responseKeys,
        }
        trackerMetaData = {
          ...trackerMetaData,
          ...respSkeleton,
        }
        if (!resp?.ok) {
          !options?.ignoreTracking &&
            logError(
              new Error('Invalid response ' + trackerMetaData?.api),
              'FETCHER_FAIL',
              {
                ...trackerMetaData,
                respData: resp && resp?.data,
              }
            )
          window.console.error(
            'FETCHER_FAIL',
            'Invalid response',
            trackerMetaData
          )
        }
      } catch (e) {
        window.console.error('api_call log error', e)
      }
      return resp
    })
    .catch((e) => {
      if (e.name === 'AbortError') {
        timeoutCallback?.()
      }
      !options?.ignoreTracking && logError(e, 'FETCHER_FAIL', trackerMetaData)
      window.console.error('FETCHER_FAIL', 'catch', trackerMetaData)
      return {
        ok: false,
      }
    })
}

export { fetcher }
