export default {
  selectedTab: '',

  //DELETE ACCOUNT
  cancellationReason: '',
  checkboxes: {
    termsAndConditionsCheck: false,
    allDataDeletionCheck: false,
    twoFADeletionCheck: false,
    migrationCheck: false,
    invoicesCheck: false,
    shopifyAppCheck: false,
  },
  deleteAccountLoader: false,
}
